import React from 'react'

export default function CopyRight() {
  return (
    <div className='flex'>
       <h2 className='m-auto'>
       © 2024 <a href={window.origin} className='text-blue-500'>khfedu.com</a>. All rights reserved.
        </h2> 
    </div>
  )
}
