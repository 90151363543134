import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { MdEmail, MdLocationPin } from "react-icons/md";
import { binanceInvestingAddress, binanceStakingV2Address } from "../utils/Abi";

const Footer = () => {
  return (
    <footer className="p-3 pt-16 min-h-screen flex justify-center items-center">
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-[15px] gap-4">
        <div className="my-[10px] px-[15px]">
          <div>Logo</div>
          <p className="mt-[15px] text-gray-400">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry lorem Ipsum has been the industry's.
          </p>
        </div>
        <div className="my-[10px] px-[15px]">
          <h1 className="text-[20px]">About Us</h1>
          <div className="w-[20%] h-[2px] bg-gray-300 mt-[5px] mb-[10px]"></div>
          <ul className="pr-2">
            <li className="my-2">
              <a
                href="/educational"
                className="inline-block py-2 pr-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
              >
                Educational
              </a>
            </li> 
            <li className="my-2">
              <a
                href="/investmentInfo"
                className="inline-block py-2 pr-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
              >
                Investment
              </a>
            </li>
            <li className="my-2">
              <a
                href="/financial"
                className="inline-block py-2 pr-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
              >
                Financial 
              </a>
            </li>
            <li className="my-2">
              <a
                href="/production"
                className="inline-block py-2 pr-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
              >
                Production
              </a>
            </li>
            <li className="my-2">
              <a
                href="/more"
                className="inline-block py-2 pr-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
              >
                More
              </a>
            </li>
            <li className="my-2">
              <a
                href="/distribution"
                className="inline-block py-2 pr-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
              >
                Distribution
              </a>
            </li>
          </ul>
        </div>
        <div className="my-[10px] px-[15px]">
          <h1 className="text-[20px]">Resources</h1>
          <div className="w-[20%] h-[2px] mt-[5px] mb-[10px]"></div>
          <ul className="pr-2">
            <li className="my-2">
              <a
                href="/"
                className="inline-block py-2 pr-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
              >
                Learn
              </a>
            </li>
            <li className="my-2">
              <a
                href="/"
                className="inline-block py-2 pr-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
              >
                Help Center
              </a>
            </li>
            <li className="my-2">
              <a
                href="/"
                className="inline-block py-2 pr-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
              >
                Platform Status
              </a>
            </li>
            <li className="my-2">
              <a 
                href="/"
                // href={`https://bscscan.com/address/${binanceStakingV2Address}`}
                // target="_blank"
                className="inline-block py-2 pr-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200" rel="noreferrer"
              >
                Staking V2
              </a>
            </li>
            <li className="my-2">
              <a
                href="/investing"
                // href={`https://bscscan.com/address/${binanceInvestingAddress}`}
                // target="_blank"
                className="inline-block py-2 pr-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200" rel="noreferrer"
              >
                Investing
              </a>
            </li>
          </ul>
        </div>
        <div className="my-[10px] px-[15px]">
          <h1 className="text-[20px]">Contact Us</h1>
          <div className="w-[20%] h-[2px] mt-[5px] mb-[10px]"></div>
          <p className="mt-[15px] mb-[25px] text-gray-400">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry lorem Ipsum.
          </p>
          <div className="p-1">
            <div className="flex items-center mb-[20px]">
              <div>
                <BsFillTelephoneOutboundFill size={25} />
              </div>
              <div className="ml-4">
                <p className="font-bold">Phone</p>
                <span className="text-gray-400">+0123 456 789</span>
              </div>
            </div>
            <div className="flex items-center mb-[20px]">
              <div>
                <MdEmail size={30} />
              </div>
              <div className="ml-4">
                <p className="font-bold">Email</p>
                <span className="text-gray-400">khfedu@gmail.com</span>
              </div>
            </div>
            <div className="flex items-center mb-[20px]">
              <div>
                <MdLocationPin size={35} />
              </div>
              <div className="ml-4">
                <p className="font-bold">Address</p>
                <address className="text-gray-400">
                  1234 North Luke Lane, South Bend, IN 360001
                </address>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
