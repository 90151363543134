import React, { useEffect, useState } from "react";
import { IoLogoUsd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { toEther, toEtherNum, toWei } from "../../utils/utils";

import {
  checkConnect,
  checkHasEnoughContractTime,
  checkHasEnoughRewardToWithdraw,
  checkIsNotBlocked,
  checkIsStaker,
  getOutputCurrency,
  getUserReward,
} from "../../hooks/Errors";
import Loading from "../Loading";
import AlertModal from "../AlertModal";
import { setShouldLoad } from "../../features/dapp/dappSlice";
import True from "../../images/true.png";
import False from "../../images/false.png";
import { toast } from "react-toastify";
import { useSigner } from "@thirdweb-dev/react";

export default function InvestingDetails() {
  const zeroAddress = "0x0000000000000000000000000000000000000000";
  const [loading, setLoading] = useState(false);
  const [alertModall, setAlertModal] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");

  const [withdrawVal, setWithdrawVal] = useState(0);
  const [rank, setRank] = useState(0);
  const [earn, setEarn] = useState<any>();
  const [reward, setReward] = useState(0);
  const [withdrawCurrency, setWithdrawCurrency] = useState("USDT");
  const [parent, setParent] = useState("");
  const [active, setActive] = useState(false);
  const [endTime, setEndTime] = useState(0);
  const [realEndTime, setRealEndTime] = useState("");
  const [isStaker, setIsStaker] = useState(false);


  //new investing states
  const [currentRefer, setCurrentRefer] = useState('');
  const [totalInvestmentAmount, setTotalInvestmentAmount] = useState(0);
  const [totalInvestReward, setTotalInvestReward] = useState(0);
  const [remainedInvestReward, setRemainedInvestReward] = useState(0);
  const [investmentStartTime, setInvestmentStartTime] = useState(0);
  const [investmentEndTime, setInvestmentEndTime] = useState(0);
  const [lastClaimedInvestRewardTime, setLastClaimedInvestRewardTime] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const [minimumInvestAmount, setMinimumInvestAmount] = useState(0);
  const [endTimePeriod, setEndTimePeriod] = useState(0);
  const [inviteRewards, setInviteRewards] = useState(0);
  const [allInviteRewardsOfUser, setAllInviteRewardsOfUser] = useState(0);
  const [userUnClaimedRewards, setUserUnClaimedRewards] = useState(0);
  const [userClaimedRewards, setUserClaimedRewards] = useState(0);
  const [userTotalInvestRewardsUntillNow, setUserTotalInvestRewardsUntillNow] = useState(0);
  const [userAllRewards, setUserAllRewards] = useState(0);
  const [previousUnclaimedInvestReward, setPreviousUnclaimedInvestReward] = useState(0);
  const [freezed, setFreezed] = useState(false);
  const [totalInvestRewardsUntillNow, setTotalInvestRewardsUntillNow] = useState(0);
  const [isUserActive, setIsUserActive] = useState(false);
  const [appIsActive, setAppIsActive] = useState(true);
  const [userTokenBalance, setUserTokenBalance] = useState(0);
  const [owner, setOwner] = useState('');

  const signer = useSigner()


  const rankNames = [
    "Intern", //0
    "Bronze", //1
    "Silver", //2
    "Gold", //3
    "Emerald", //4
    "Ruby", //5
    "Jade", //6
    "Diamond", //7
    "Red Diamond", //8
    "Black Diamond", //9
    "Koh-i-Noor", //10
  ];

  const dispatch = useDispatch();
  const shouldLoad = useSelector((state: any) => state.counter.shouldLoad);
  const account = useSelector((state: any) => state.counter.account);
  const library = useSelector((state: any) => state.counter.library);
  const tokenContract = useSelector(
    (state: any) => state.counter.tokenContract
  );
  const stakingContract = useSelector(
    (state: any) => state.counter.stakingContract
  );
  const stakingV2Contract = useSelector(
    (state: any) => state.counter.stakingV2Contract
  );

  const investingTokenContract = useSelector(
    (state: any) => state.counter.investingTokenContract
  );
  const investingContract = useSelector(
    (state: any) => state.counter.investingContract
  );

  const returnTime = async (endTime: any) => {
    if (endTime === 0) return;
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    var date = new Date(endTime * 1000);
    // year part from the timestamp
    var year = date.getUTCFullYear();
    // Minutes part from the timestamp
    var month = "0" + date.getMonth();
    // Seconds part from the timestamp
    var day = "0" + date.getDay();
    console.log(`${year}/${month}/${day}`);
    setRealEndTime(date.toUTCString());


    //test Time

  };




  // const returnTime2 = async (endTime0: any) => {
  //   const endTime = 1776861957;
  //   // if (endTime === 0) return;
  //   // Create a new JavaScript Date object based on the timestamp
  //   // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  //   var date = new Date(endTime * 1000);
  //   // year part from the timestamp
  //   var year = date.getUTCFullYear();
  //   // Minutes part from the timestamp
  //   var month = "0" + date.getUTCMonth();
  //   // Seconds part from the timestamp
  //   var day = "0" + date.getDay();
  //   console.log(`${year}/${month}/${day}`);
  //   // setRealEndTime(date.toDateString());
  //   console.log("", date.toUTCString());

  //   //test Time

  // };

  // useEffect(() => {
  //   returnTime2(0);
  // },[])


  


  const checkIsUserActive = (totalInvestmentReward:number, investmentEndTime:number, allInviteRewards:number, totalInvestRewardsUntillNow:number) => {
    const timestamp = Math.floor(Date.now() / 1000);
    // console.log("ZZZ", timestamp, investmentEndTime);
    if(totalInvestmentReward > 0 && investmentEndTime > 0){
    if(allInviteRewards + totalInvestRewardsUntillNow > totalInvestmentReward){
        return false;
    } else if(timestamp > investmentEndTime){
        return false;
    } else {
        return true;
    }
    }else {
        return false;
    }
  }


  const getUserInvestDetails = async () => {

    const investInfo = await investingContract.investInfo(account);
    setCurrentRefer(investInfo[0]);
    setTotalInvestmentAmount(toEtherNum(investInfo[1]));
    setTotalInvestReward(toEtherNum(investInfo[2]));
    setRemainedInvestReward(toEtherNum(investInfo[3]));
    setInvestmentStartTime(Number(investInfo[4]));
    setInvestmentEndTime(Number(investInfo[5]));
    setLastClaimedInvestRewardTime(Number(investInfo[6]));
    setIsActive(investInfo[7]);

    returnTime(Number(investInfo[5]));

    console.log("investInfo", investInfo);
    console.log("refer", investInfo[0]);
    console.log("totalInvestmentAmount", toEtherNum(investInfo[1]));
    console.log("totalInvestReward", toEtherNum(investInfo[2]));
    console.log("remainedInvestReward", toEtherNum(investInfo[3]));
    console.log("investmentStartTime", Number(investInfo[4]));
    console.log("investmentEndTime", Number(investInfo[5]));
    console.log("lastClaimedInvestRewardTime", Number(investInfo[6]));
    console.log("isActive", investInfo[7]);

    // const minimumInvestAmount = await investingContract.minimumInvestAmount();
    // setMinimumInvestAmount(toEtherNum(minimumInvestAmount));
    // console.log("minimumInvestAmount", toEtherNum(minimumInvestAmount));
    // const endTimePeriod = await investingContract.endTimePeriod();
    // setEndTimePeriod(Number(endTimePeriod));
    // console.log("endTimePeriod", Number(endTimePeriod));
    const inviteRewards = await investingContract.inviteRewards(account);
    setInviteRewards(toEtherNum(inviteRewards));
    console.log("inviteRewards", toEtherNum(inviteRewards));
    const allInviteRewards = await investingContract.allInviteRewards(account);
    setAllInviteRewardsOfUser(toEtherNum(allInviteRewards));
    console.log("allInviteRewards", toEtherNum(allInviteRewards));
    const userUnClaimedRewards = await investingContract.getUnclaimedRewards(account);
    setUserUnClaimedRewards(toEtherNum(userUnClaimedRewards));
    console.log("userUnClaimedRewards", toEtherNum(userUnClaimedRewards));
    const userClaimedRewards = await investingContract.userClaimedRewards(account);
    setUserClaimedRewards(toEtherNum(userClaimedRewards));
    console.log("userClaimedRewards", toEtherNum(userClaimedRewards));
    // const userTotalInvestRewardsUntillNow = await investingContract.getTotalInvestRewardsUntillNow(account);
    // setUserTotalInvestRewardsUntillNow(toEtherNum(userTotalInvestRewardsUntillNow));
    // console.log("userTotalInvestRewardsUntillNow", toEtherNum(userTotalInvestRewardsUntillNow));
    const userAllRewards = await investingContract.userAllRewards(account);
    setUserAllRewards(toEtherNum(userAllRewards));
    console.log("userAllRewards", toEtherNum(userAllRewards));
    const previousUnclaimedInvestReward = await investingContract.previousUnclaimedInvestReward(account);
    setPreviousUnclaimedInvestReward(toEtherNum(previousUnclaimedInvestReward));
    console.log("previousUnclaimedInvestReward", toEtherNum(previousUnclaimedInvestReward));
    const freezed = await investingContract.freezed(account);
    setFreezed(freezed);
    console.log("freezed", freezed);
    const totalInvestRewardsUntillNow = await investingContract.getTotalInvestRewardsUntillNow(account);
    setTotalInvestRewardsUntillNow(toEtherNum(totalInvestRewardsUntillNow));
    console.log("totalInvestRewardsUntillNow", toEtherNum(totalInvestRewardsUntillNow));
    const isUserActive = checkIsUserActive(toEtherNum(investInfo[2]), Number(investInfo[5]), toEtherNum(allInviteRewards), toEtherNum(totalInvestRewardsUntillNow));
    setIsUserActive(isUserActive);
    console.log("isUserActive", (isUserActive));
    const appIsUnActive = await investingContract.paused();
    setAppIsActive(!appIsUnActive);
    console.log("appIsActive", (!appIsUnActive));
    // const userTokenBalance = await investingTokenContract.balanceOf(account);
    // setUserTokenBalance(toEtherNum(userTokenBalance));
    // console.log("user token balance", toEther(userTokenBalance));
    // const owner = await investingContract.owner();
    // setOwner(owner);
    // console.log("Owner", owner);
    
  }

  useEffect(() => {
    if (investingContract) {
      getUserInvestDetails();
    }
    if (!account || !investingContract) {
      setRank(0);
      setEarn(0);
      setActive(false);
      setEndTime(0);
      setRealEndTime("");
      setParent("");
    }
  }, [investingContract, account, shouldLoad, library]);

  const withdrawReward = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      if(freezed==true) { 
        toast.error("You can not withdraw because you are blocked")
        return setLoading(false);
      }
      

      if (investingContract) {
        // const signer = await library.getSigner();
        let res;
        res = await investingContract.connect(signer).claimAllReward({ gasLimit: 400000 });
        const receipt = await res.wait();
        if (receipt.status == 1) {
          await getUserInvestDetails();
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
        } else {
          setLoading(false);
          console.log(receipt)
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  return (
    <div
      className="w-[90%] sm:w-10/12 px-3 py-20 my-24 mx-auto border border-yellow-400 rounded-md"
      id="Reward"
    >
      {parent === zeroAddress ? (
        <h4 className="text-red-800 p-4 font-bold">
          You are not Active, please active first.
        </h4>
      ) : null}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="flex flex-col mb-8 lg:mb-0 px-4">
          <div className="mb-6">
            <b>Your unClaimed invest Reward :</b> {userUnClaimedRewards} USD
          </div>
          <div className="mb-6">
            <b>Unclaimed invest Reward from previous period :</b> {previousUnclaimedInvestReward} USD
          </div>
          <div className="mb-6">
            <b>Your unClaimed Invite Earn :</b> {inviteRewards} USD
          </div>
          <div className="mb-6">
            <b>total unClaimed invest reward and invite Earn :</b> {userAllRewards} USD
          </div>
          <div>
            <b>Withdraw Currency :</b> {withdrawCurrency}
          </div>
        </div>
        <div className="flex flex-col px-4">
          <div className="mb-6 ">
            <div className="">
              <p>
                <b>Parent : </b>
              </p>
              <p className="text-sm max-w-full break-words ">
                {currentRefer.toString()}
              </p>
              {/* {`${parent.toString().slice(0, 5)} .... ${parent
                .toString()
                .slice(-6, -1)}`} */}
            </div>
          </div>
          <div className="mb-6 ">
            <div className="grid gap-3">
              <p>
                <b>Your Investment : </b> {totalInvestmentAmount} USD
              </p>
              <p>
                {/* <b>Your Total Reward : </b> {totalInvestReward} USD */}
                <b>Your Total Reward : </b> {totalInvestmentAmount*180/100} USD
              </p>
              <p>
                <b>Your Claimed invest Rewards : </b> {allInviteRewardsOfUser + totalInvestRewardsUntillNow + (totalInvestmentAmount*180/100 - totalInvestReward)} USD
              </p>
            </div>
          </div>
          <div className="mb-6 flex text-center items-center">
            {/* <b>Status :</b> {active ? "ACTIVE" : "UNACTIVE"} */}
            <p>
              <b>Status :</b>
            </p>{" "}
            <img width="50px" height="50px" src={isUserActive ? True : False} />
          </div>
          <div className="mb-6 gap-2 flex">
            <b>End Time : </b> <p>{realEndTime}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mt-8">
        <form>
          <label className="block mb-3" htmlFor="withdraw">
            <b>Withdraw your reward (max {userAllRewards} $)</b>
          </label>
          <div className="relative">
            <input
              className="w-full border bg-gray-700 border-yellow-400 rounded-2xl p-[12px] pl-[20px] pr-[40px] placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
              type="number"
              id="withdraw"
              disabled
              value={userAllRewards}
              onChange={(e: any) => setWithdrawVal(e.target.value)}
            />
            <IoLogoUsd className="absolute top-[50%] translate-y-[-50%] right-3" />
          </div>
          <button
            type="button"
            onClick={withdrawReward}
            className="flex justify-center mx-auto items-center p-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-500 to-yellow-200 hover:bg-gradient-to-bl rounded-lg transition-all"
          >
            Withdraw
          </button>
        </form>
      </div>
      {loading ? <Loading loading={loading} setLoading={setLoading} /> : null}
      {alertModall ? (
        <AlertModal
          alertModal={alertModall}
          status={alertStatus}
          setAlertModal={setAlertModal}
          setStatus={setAlertStatus}
        />
      ) : null}
    </div>
  );
}
