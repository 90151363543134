import React, { useEffect, useState } from "react";
import { IoLogoUsd } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { toEther, toWei } from "../utils/utils";

import {
  checkConnect,
  checkHasEnoughContractTime,
  checkHasEnoughRewardToWithdraw,
  checkIsNotBlocked,
  checkIsStaker,
  getOutputCurrency,
  getUserReward,
} from "../hooks/Errors";
import Loading from "./Loading";
import AlertModal from "./AlertModal";
import { setShouldLoad } from "../features/dapp/dappSlice";
import True from "../images/true.png";
import False from "../images/false.png";
import { useSigner } from "@thirdweb-dev/react";

export default function Details() {
  const zeroAddress = "0x0000000000000000000000000000000000000000";
  const [loading, setLoading] = useState(false);
  const [alertModall, setAlertModal] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");

  const [withdrawVal, setWithdrawVal] = useState(0);
  const [rank, setRank] = useState(0);
  const [earn, setEarn] = useState<any>();
  const [reward, setReward] = useState(0);
  const [withdrawCurrency, setWithdrawCurrency] = useState("USDT");
  const [parent, setParent] = useState("");
  const [active, setActive] = useState(false);
  const [endTime, setEndTime] = useState(0);
  const [realEndTime, setRealEndTime] = useState("");
  const [isStaker, setIsStaker] = useState(false);

  const signer = useSigner()

  const rankNames = [
    "Intern", //0
    "Bronze", //1
    "Silver", //2
    "Gold", //3
    "Emerald", //4
    "Ruby", //5
    "Jade", //6
    "Diamond", //7
    "Red Diamond", //8
    "Black Diamond", //9
    "Koh-i-Noor", //10
  ];

  const dispatch = useDispatch();
  const shouldLoad = useSelector((state: any) => state.counter.shouldLoad);
  const account = useSelector((state: any) => state.counter.account);
  const library = useSelector((state: any) => state.counter.library);
  const tokenContract = useSelector(
    (state: any) => state.counter.tokenContract
  );
  const stakingContract = useSelector(
    (state: any) => state.counter.stakingContract
  );

  const returnTime = async (endTime: any) => {
    if (endTime === 0) return;
    // Create a new JavaScript Date object based on the timestamp
    // multiplied by 1000 so that the argument is in milliseconds, not seconds.
    var date = new Date(endTime * 1000);
    // year part from the timestamp
    var year = date.getUTCFullYear();
    // Minutes part from the timestamp
    var month = "0" + date.getMonth();
    // Seconds part from the timestamp
    var day = "0" + date.getDay();
    console.log(`${year}/${month}/${day}`);
    setRealEndTime(`${year}/${month}/${day}`);
  };

  const loadData = async () => {
    const owner = await stakingContract.owner();
    const details = await stakingContract.stakers(account);
    const reward = await getUserReward(stakingContract, account);
    const outputCurrency = await getOutputCurrency(stakingContract);
    setWithdrawCurrency(outputCurrency as string);
    if (details) {
      setRank(details?.rank);
      setEarn(toEther(details?.earn));
      setActive(details?.active);
      setEndTime(Number(details?.contractEndTime));
      if (Number(details?.contractEndTime)) {
        returnTime(Number(details?.contractEndTime));
      } else {
        setRealEndTime("");
      }
      setParent(details?.parent);
      if (
        details?.parent === "0x0000000000000000000000000000000000000000" &&
        account !== owner
      ) {
        setIsStaker(true);
      }
    } else {
      setRealEndTime("");
    }

    setReward(Number(reward));

    dispatch(setShouldLoad(false as any));
  };

  useEffect(() => {
    if (stakingContract) {
      loadData();
    }
    if (!stakingContract || !account) {
      setRank(0);
      setEarn(0);
      setActive(false);
      setEndTime(0);
      setRealEndTime("");
      setParent("");
    }
  }, [stakingContract, account, shouldLoad, library]);

  const withdrawReward = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      const isStaker = await checkIsStaker(stakingContract, account);
      if (isStaker === false) return setLoading(false);
      const hasEnoughReward = await checkHasEnoughRewardToWithdraw(
        withdrawVal,
        stakingContract,
        account
      );
      if (hasEnoughReward === false) return setLoading(false);
      const hasContractTime = await checkHasEnoughContractTime(
        stakingContract,
        account
      );
      if (hasContractTime === false) return setLoading(false);
      // const isActiveToReward = await checkIsActiveToReward(
      //   stakingContract,
      //   account
      // );
      // if (isActiveToReward === false) return setLoading(false);
      const isNotBlocked = await checkIsNotBlocked(stakingContract, account);
      if (isNotBlocked === false) return setLoading(false);

      if (stakingContract) {
        // const signer = await library.getSigner();
        const rewardCurrency = await stakingContract.outputToken();
        let res;
        if (rewardCurrency === 0) {
          res = await stakingContract.connect(signer).withdrawRewardsUsdt(
            toWei(reward.toString()),
            { gasLimit: 400000 }
          );
        }
        if (rewardCurrency === 1) {
          res = await stakingContract.connect(signer).withdrawRewardsEther(
            toWei(reward.toString()),
            { gasLimit: 400000 }
          );
        }
        const receipt = await res.wait();
        if (receipt.status == 1) {
          await loadData();
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
        } else {
          setLoading(false);
          console.log(receipt)
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  return (
    <div
      className="w-[90%] sm:w-10/12 px-3 py-20 my-24 mx-auto border border-solid rounded-md"
      id="Reward"
    >
      {parent === zeroAddress ? (
        <h4 className="text-red-800 p-4 font-bold">
          You are not Active, please active first.
        </h4>
      ) : null}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
        <div className="flex flex-col mb-8 lg:mb-0 px-4">
          <div className="mb-6">
            <b>Your Rank :</b> {rankNames[rank]} ({rank})
          </div>
          <div className="mb-6">
            <b>Rank Earn :</b> {earn}
          </div>
          <div className="mb-6">
            <b>Reward :</b> {reward}
          </div>
          <div>
            <b>Withdraw Currency :</b> {withdrawCurrency}
          </div>
        </div>
        <div className="flex flex-col px-4">
          <div className="mb-6 ">
            <div className="">
              <p>
                <b>Parent : </b>
              </p>
              <p className="text-sm max-w-full break-words ">
                {parent.toString()}
              </p>
              {/* {`${parent.toString().slice(0, 5)} .... ${parent
                .toString()
                .slice(-6, -1)}`} */}
            </div>
          </div>
          <div className="mb-6 flex text-center items-center">
            {/* <b>Status :</b> {active ? "ACTIVE" : "UNACTIVE"} */}
            <p>
              <b>Status :</b>
            </p>{" "}
            <img width="50px" height="50px" src={active ? True : False} />
          </div>
          <div className="mb-6 gap-2 flex">
            <b>End Time : </b> <p>{realEndTime}</p>
          </div>
        </div>
      </div>
      <div className="flex justify-center items-center mt-8">
        <form>
          <label className="block mb-3" htmlFor="withdraw">
            <b>Withdraw your reward (max {reward} $)</b>
          </label>
          <div className="relative">
            <input
              className="w-full border bg-gray-700 border-gray-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
              type="number"
              id="withdraw"
              disabled
              value={reward}
              onChange={(e: any) => setWithdrawVal(e.target.value)}
            />
            <IoLogoUsd className="absolute top-[50%] translate-y-[-50%] right-3" />
          </div>
          <button
            type="button"
            onClick={withdrawReward}
            className="flex justify-center mx-auto items-center p-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-lg transition-all"
          >
            Withdraw
          </button>
        </form>
      </div>
      {loading ? <Loading loading={loading} setLoading={setLoading} /> : null}
      {alertModall ? (
        <AlertModal
          alertModal={alertModall}
          status={alertStatus}
          setAlertModal={setAlertModal}
          setStatus={setAlertStatus}
        />
      ) : null}
    </div>
  );
}
