
const InvestmentInfo = () => {
    return (
      <footer className="p-3 pt-0 min-h-screen flex justify-center items-center">
        {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-[15px] gap-4"> */}
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 px-[15px] gap-4">
          <div className="my-[1px] px-[1px]">
            <div>Investment:</div>
            <p className="mt-[1px] text-gray-400">
            -One of the most potential methods in financial markets is deposit or investment, which is also called stick.
  - People who have less skill or experience or even people who are professionals and have high skills use this profitable method and create so-called liquidity leverage.
  - Again, at a time when many are in doubt, KHF came on the scene and solved this problem. Another service of KHF is to provide users with an amazing investment system.
  A system that will surprise the user with the complexities in this large and limitless market.
            </p>
          </div>
          
        </div>
      </footer>
    );
  };
  
  export default InvestmentInfo;
  