import { useState, useEffect, useRef } from "react";
import Globe from "react-globe.gl";
import background from "../images/background.png";
import Header from "./Header";
import ForexLinksDropDown from "./ForexLinksDropDown";

export default function Home() {
  const [{ globeHeight }, setGlobeHeight] = useState({
    globeHeight: 200,
  });
  const [{ appWidth, appHeight }, setState] = useState({
    appHeight: 45,
    appWidth: window.innerWidth,
  });

  const globeEl = useRef();

  const GLOBE_IMAGE_URL = "https://unpkg.com/three-globe@2.18.5/example/img/earth-blue-marble.jpg";
  // const GLOBE_IMAGE_URL = "https://unpkg.com/three-globe@2.18.5/example/img/earth-day.jpg";

  useEffect(() => {
    const updateDimensions = () => {
      setState((prev) => ({
        ...prev,
        appWidth: window.innerWidth,
        appHeight: window.innerHeight - 10,
      }));
      setGlobeHeight({
        globeHeight: (window.innerHeight - 10) * 0.5,
      });
    };

    window.addEventListener("resize", updateDimensions);
    updateDimensions();
    return () => window.removeEventListener("resize", updateDimensions);
  }, [appHeight]);

  useEffect(() => {
    if (globeEl?.current) {
      //@ts-ignore
      globeEl.current.controls().autoRotate = true;
      //@ts-ignore
      globeEl.current.controls().autoRotateSpeed = 1;
      //@ts-ignore
      // globeEl.current.camera().setViewOffset(100, 100, 0, -13, 100, 100);

      
    }
  }, []);

  return (
    <>
      <Header />
      <div className={`h-[${globeHeight}px] transition-all`} id="Home">
        <Globe
          ref={globeEl}
          height={globeHeight}
          width={appWidth}
          globeImageUrl={GLOBE_IMAGE_URL}
          backgroundImageUrl={background}
          enablePointerInteraction={false}
        />
      </div>
      <div className="flex flex-col justify-center items-center px-2">
        <h1 className="text-[30px] font-[800]">K H F</h1>
        <p className="mt-5">world of education</p>
        <div className="flex gap-5">
        
        <a
          href="/Investing"
          className="flex justify-center items-center py-3 px-5 mt-6 font-[400] md:px-[2.3rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-lg transition-all"
        >
          Invest
        </a>
        {/* <a
          href="#Connect"
          className="flex justify-center items-center py-3 px-3.5 mt-6 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-lg transition-all"
        >
          Explore
        </a> */}
        <ForexLinksDropDown />
        </div>
      </div>
    </>
  );
}
