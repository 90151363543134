import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setShouldLoad } from "../features/dapp/dappSlice";
import {
  checkAppIsActive,
  checkConnect,
  checkIsShouldStake,
  checkIsStakeAble,
  getRefer,
  getStakeAmount,
  getStakeAmountV2,
  hasEnoughBalance,
} from "../hooks/Errors";
import coin from "../images/coin.png";
import logo from "../images/logo.png";
import logoG from "../images/logo-gray.png";

import { StakingDetails } from "../types/Stake";
import { toEther, toWei } from "../utils/utils";
import AlertModal from "./AlertModal";
import Loading from "./Loading";
import { IoLogoUsd } from "react-icons/io";
import { useSigner } from "@thirdweb-dev/react";

export default function StakeV2() {
  const [loading, setLoading] = useState(false);
  const [alertModall, setAlertModal] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const [shouldStaked, setShouldStaked] = useState(true);

  const [rank, setRank] = useState(0);
  const [stakeAmount, setStakeAmount] = useState(30);
  const [stakeFee, setStakeFee] = useState(5);
  const [totalStakeAmount, setTotalStakeAmount] = useState(33);
  const [stakeCurrency, setStakeCurrency] = useState("USDT");
  const [payAmount, setPayAmount] = useState<any>('');
  const [isApproved, setIsApproved] = useState(false);
  const [stakeVal, setStakeVal] = useState<number>(30)

  const signer = useSigner()

  const dispatch = useDispatch();

  const account = useSelector((state: any) => state.counter.account);
  const library = useSelector((state: any) => state.counter.library);

  const tokenContract = useSelector(
    (state: any) => state.counter.tokenContract
  );
  const stakingContract = useSelector(
    (state: any) => state.counter.stakingContract
  );
  const stakingV2Contract = useSelector(
    (state: any) => state.counter.stakingV2Contract
  );

  const loadData = async () => {
    const details = await getStakeAmountV2(stakingV2Contract, account);
    const shouldStake = await checkIsShouldStake(stakingV2Contract, account);
    setShouldStaked(shouldStake);
    setRank(details?.rank);
    setStakeAmount(details?.stakeAmount as number);
    setStakeFee(details?.fee as number);
    setTotalStakeAmount(details?.totalAmount as number);
    setStakeCurrency(details?.stakeCurrency as string);
    setPayAmount(details?.payAmount)
    // console.log(await library.getBalance(account))
    // if(details?.rank == 0 && stakeCurrency == "BNB"){
    //   const amountToPay = details?.rank == 0 ? payAmount : details?.totalAmount;
    //   const bnbAmount = await stakingV2Contract.getAmountEthOut(toWei(amountToPay.toString() as string));
    //   setPayAmount(toEther(bnbAmount));
    // }
  };

  const approve = async () => {
    // console.log("HHH")
    console.log("USDT address:", tokenContract.address)
    checkConnect(account);
    if(tokenContract){
      setLoading(true);
      const appIsActive = await checkAppIsActive(stakingV2Contract);
        if(appIsActive==false) return setLoading(false);
        const stakeDetails = await getStakeAmountV2(stakingV2Contract, account);
        const hasBalance = await hasEnoughBalance(
          stakingV2Contract,
          tokenContract,
          library,
          account,
          totalStakeAmount
        );
        if (hasBalance === false) return setLoading(false);
        const refer = await getRefer(
          stakingV2Contract,
          account,
          window.location.href
        );
        
        if (refer?.isCorrect === false) return setLoading(false);
        const isStakeable = await checkIsStakeAble(stakingV2Contract, account);
        if (isStakeable === false) return setLoading(false);
        if (stakeDetails?.stakeCurrency === "USDT") {
        // const signer = await library.getSigner();
        if(stakeDetails?.rank == 0){
          if(stakeVal > 300 || stakeVal < 30){
          toast.error("Amount to pay should be between 30 and 300 $");
          setLoading(false);
          return;
          }
        }
        const amountToPay = stakeDetails?.rank == 0 ? payAmount : stakeDetails?.totalAmount;
        await tokenContract.connect(signer).approve(stakingV2Contract.address, toWei(amountToPay.toString()));
        setLoading(false);
        setIsApproved(true);
        }
    }
  }

  const stake = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      if (stakingV2Contract) {
        
        const appIsActive = await checkAppIsActive(stakingV2Contract);
        if(appIsActive==false) return setLoading(false);
        const stakeDetails = await getStakeAmountV2(stakingV2Contract, account);
        const hasBalance = await hasEnoughBalance(
          stakingV2Contract,
          tokenContract,
          library,
          account,
          totalStakeAmount
        );
        if (hasBalance === false) return setLoading(false);
        const refer = await getRefer(
          stakingV2Contract,
          account,
          window.location.href
        );
        
        if (refer?.isCorrect === false) return setLoading(false);
        const isStakeable = await checkIsStakeAble(stakingV2Contract, account);
        if (isStakeable === false) return setLoading(false);
        let res;
        if(stakeDetails?.stakeCurrency === "USDT" && isApproved ===false){toast.error("Please First approve");setLoading(false);return}
        if (stakeDetails?.stakeCurrency === "USDT") {
          // const signer = await library.getSigner();
          if(stakeDetails?.rank == 0){
            if(stakeVal > 300 || stakeVal < 30){
            toast.error("Amount to pay should be between 30 and 300 $");
            setLoading(false);
            return;
            }
          }
          const amountToPay = stakeDetails?.rank == 0 ? payAmount : stakeDetails?.totalAmount;
          const amountToSay = stakeDetails?.rank == 0 ? stakeVal : stakeDetails?.stakeAmount;
          // await tokenContract.connect(signer).approve(stakingContract.address, toWei(stakeDetails.totalAmount.toString()));
          res = await stakingV2Contract.connect(signer).stakeUsdt(refer?.address, toWei(amountToSay.toString()), {
            gasLimit: 400000,
          });
        }
        if (stakeDetails?.stakeCurrency === "BNB") {
          // const signer = await library.getSigner();
          const details = await getStakeAmountV2(stakingV2Contract, account);
          if(stakeDetails?.rank == 0){
            if(stakeVal > 300 || stakeVal < 30){
            toast.error("Amount to pay should be between 30 and 300 $");
            setLoading(false);
            return;
            }
          }
          const amountToPay = details?.rank == 0 ? stakeVal*(100+stakeFee)/100 : details?.totalAmount;
          const amountToSay = details?.rank == 0 ? stakeVal : details?.stakeAmount;
          const bnbAmount = await stakingV2Contract.getAmountEthOut(toWei((amountToPay as number).toString() as string));
          res = await stakingV2Contract.connect(signer).stakeEth(refer?.address, toWei((amountToSay as number).toString()), {
            value:bnbAmount,
            gasLimit: 400000,
          });
        }
        const receipt = await res.wait();
        if (receipt.status == 1) {
          await loadData();
          dispatch(setShouldLoad(true  as any));
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (stakingV2Contract && account) {
      loadData();
    }
    if(!stakingV2Contract || !account){
      setShouldStaked(true);
    setRank(0);
    setStakeAmount(30);
    setStakeFee(5);
    setTotalStakeAmount(31.5);
    setPayAmount(31.5)
    setStakeCurrency("USDT")
    }
  }, [account, stakingV2Contract]);

  const calculatePayAmount = async () => {
    if(rank == 0){
      setStakeAmount(stakeVal);
      setTotalStakeAmount(stakeVal*(100+stakeFee)/100);
      setPayAmount(stakeVal*(100+stakeFee)/100);
    }
    if(rank == 0 && stakeCurrency == "BNB"){
      const bnbAmount = await stakingV2Contract.getAmountEthOut(toWei(payAmount.toString() as string));
      setPayAmount(toEther(bnbAmount));
    }

  }
  useEffect(() => {
    calculatePayAmount()
  },[stakeVal])

  return (
    <div
      className="w-[90%] sm:w-10/12 px-3 py-20 my-24 mx-auto border border-blue-400 rounded-md"
      id="Stake"
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        <div className="flex items-center justify-center lg:justify-start px-7">
          <img src={shouldStaked ? logoG :logo} alt="Coin Image" />
        </div>
        <div className="flex flex-col justify-center px-7 order-first lg:order-last mb-8 lg:mb-0">
          <h1 className="text-[25px] font-[600]">Active V2</h1>
          <p className="mt-5">
            Your rank is {rank} so you should register {stakeAmount}$ + {stakeFee}%
            fee.
          </p>
          <p className="mt-5"><b>Active Currency:</b> {stakeCurrency} </p>
          <p className="mt-5"><b>You should pay:</b> {payAmount} {stakeCurrency} </p>
          {/* <p className="mt-5"><b>Your Balance:</b> {} </p> */}
          {rank === 0 ? (
          <div className="flex justify-center items-center mt-8">
          <div className="relative">
          <input
              className="w-full border bg-gray-700 border-gray-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
              type="number"
              id="stake"
              placeholder="between 30 to 300"
              value={stakeVal}
              onChange={(e: any) => setStakeVal(e.target.value)}
            />
            <IoLogoUsd className="absolute top-[50%] translate-y-[-50%] right-3" />
            </div>
            
            </div>
            ) : null}
          {stakeCurrency === "USDT" && !isApproved ? <button
            type="button"
            onClick={approve}
            // disabled={true}
            className="flex justify-center items-center p-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-900 to-blue-200 hover:bg-gradient-to-bl rounded-lg transition-all"
          >
            Approve
          </button> : null}
          <button
            type="button"
            onClick={stake}
            className="flex justify-center items-center p-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-lg transition-all"
          >
            Active
          </button>
        </div>
      </div>
      {loading ? <Loading loading={loading} setLoading={setLoading} /> : null}
      {alertModall ? (
        <AlertModal
          alertModal={alertModall}
          status={alertStatus}
          setAlertModal={setAlertModal}
          setStatus={setAlertStatus}
        />
      ) : null}
    </div>
  );
}
