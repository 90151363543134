import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  applicationStatus,
  checkConnect,
  checkOwner,
  getActiveUsers,
  getBlockedUsers,
  getContractFreeBalance,
  getDeActiveUsers,
  getInputCurrency,
  getOutputCurrency,
  getUserReward,
} from "../../hooks/Errors";
import { toEtherNum, toWei } from "../../utils/utils";
import AlertModal from "../AlertModal";
import Loading from "../Loading";
import ReactPaginate from "react-paginate";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import Header from "../Header";
import InvestingHeader from "./InvestingHeader";
import { toast } from "react-toastify";
import { useSigner } from "@thirdweb-dev/react";

const InvestingPanel = () => {
  const [loading, setLoading] = useState(false);
  const [alertModall, setAlertModal] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const [stakeCurrency, setStakeCurrency] = useState("USDT");
  const [withdrawCurrency, setWithdrawCurrency] = useState("USDT");
  const [status, setStatus] = useState("ACTIVE");
  const [ownerRewards, setOwnerRewards] = useState(0);
  const [freeBalance, setFreeBalance] = useState(0);
  const [allContractBalance, setAllContractBalance] = useState(0);
  const [allRewardsBalance, setAllRewardsBalance] = useState(0);
  const [currentOwner, setCurrentOwner] = useState("");
  const [activeUsers, setActiveUsers] = useState<any>([]);
  const [deActiveUsers, setDeActiveUsers] = useState<any>([]);
  const [blockedUsers, setBlockedUsers] = useState<any>([]);
  const [ownerRewardVal, setOwnerRewardVal] = useState("");
  const [appVal, setAppVal] = useState("");
  const [currentOwnerVal, setCurrentOwnerVal] = useState("");
  const [blockedSearch, setBlockedSearch] = useState("");
  //new
  const [ownerShareAmount, setOwnerShareAmount] = useState(0);
  const [userUnClaimedRewards, setUserUnClaimedRewards] = useState(0);
  const [inviteRewards, setInviteRewards] = useState(0);
  const [userAllRewards, setUserAllRewards] = useState(0);
  const [previousUnclaimedInvestReward, setPreviousUnclaimedInvestReward] = useState(0);
  const [contractBalance, setContractBalance] = useState(0);
  const [allUsersInviteRewardAmount, setAllUsersInviteRewardAmount] = useState(0);
  const [estimateOneMonthInvestRewards, setEstimateOneMonthInvestRewards] = useState(0);
  const [allUsersUnclaimedRewardsUntillNow, setAllUsersUnclaimedRewardsUntillNow] = useState(0);
  const [ownerShareAmountVal, setOwnerShareAmountVal] = useState("");
  const [infoAddressVal1, setInfoAddressVal1] = useState("");
  const [infoAddressVal2, setInfoAddressVal2] = useState("");
  const [userInfoLoading, setUserInfoLoading] = useState(false);
  const [depositTokenVal, setDepositTokenVal] = useState("");
  const [withdrawBalanceVal, setWithdrawBalanceVal] = useState("");
  const [userTokenBalance, setUserTokenBalance] = useState(0);
  const [userTotalInvestAmount, setUserTotalInvestAmount] = useState(0);
  const [userTotalInvestReward, setUserTotalInvestReward] = useState(0);
  const [userClaimedRewards, setUserClaimedRewards] = useState(0);
  const [userAllInviteRewards, setUserAllInviteRewards] = useState(0);
  const [userTotalInvestRewardsUntillNow, setUserTotalInvestRewardsUntillNow] = useState(0);
  //histories
  const [historyInvestAmount, setHistoryInvestAmount] = useState(0);
  const [historyDepositByOwner, setHistoryDepositByOwner] = useState(0);
  const [historyClaimedAllRewards, setHistoryClaimedAllRewards] = useState(0);
  const [historyClaimedInviteRewards, setHistoryClaimedInviteRewards] = useState(0);
  const [historyClaimedOwnerInviteRewards, setHistoryClaimedOwnerInviteRewards] = useState(0);
  const [historyClaimedInvestRewards, setHistoryClaimedInvestRewards] = useState(0);
  const [historyWithdrawOwnerShare, setHistoryWithdrawOwnerShare] = useState(0);
  const [historyWithdrawFreeBalance, setHistoryWithdrawFreeBalance] = useState(0);
  const [historyWithdrawEmergency, setHistoryWithdrawEmergency] = useState(0);
  //extra
  const [isApproved, setIsApproved] = useState(false);



  const signer = useSigner()
  

  

  //new variables
  

  const account = useSelector((state: any) => state.counter.account);
  const library = useSelector((state: any) => state.counter.library);

  const tokenContract = useSelector(
    (state: any) => state.counter.tokenContract
  );
  const stakingContract = useSelector(
    (state: any) => state.counter.stakingContract
  );
  const stakingV2Contract = useSelector(
    (state: any) => state.counter.stakingV2Contract
  );
  const investingTokenContract = useSelector(
    (state: any) => state.counter.investingTokenContract
  );
  const investingContract = useSelector(
    (state: any) => state.counter.investingContract
  );

  

  

  const getUserRewards = async (user:string) => {
    setUserInfoLoading(true);
    const investInfo = await investingContract.investInfo(user);
    setUserTotalInvestAmount(toEtherNum(investInfo[1]));
    setUserTotalInvestReward(toEtherNum(investInfo[2]));
    const userClaimedRewards = await investingContract.userClaimedRewards(user);
    setUserClaimedRewards(toEtherNum(userClaimedRewards));
    const userAllRewards = await investingContract.userAllRewards(user);
    setUserAllRewards(toEtherNum(userAllRewards));
    const inviteRewards = await investingContract.inviteRewards(user);
    setInviteRewards(toEtherNum(inviteRewards));
    const userUnClaimedRewards = await investingContract.getUnclaimedRewards(user);
    setUserUnClaimedRewards(toEtherNum(userUnClaimedRewards));
    const previousUnclaimedInvestReward = await investingContract.previousUnclaimedInvestReward(user);
    setPreviousUnclaimedInvestReward(toEtherNum(previousUnclaimedInvestReward));
    const userAllInviteRewards = await investingContract.allInviteRewards(user);
    setUserAllInviteRewards(toEtherNum(userAllInviteRewards));
    const userTotalInvestRewardsUntillNow = await investingContract.getTotalInvestRewardsUntillNow(user);
    setUserTotalInvestRewardsUntillNow(toEtherNum(userTotalInvestRewardsUntillNow));;;
    setUserInfoLoading(false);
  } 

  const LoadInvestData = async () => {
    const owner = await investingContract.owner();
    setCurrentOwner(owner);
    const appIsUnActive = await investingContract.paused();
    setStatus(appIsUnActive ? "STOPPED" : "ACTIVE");
    const ownerRewards = await investingContract.inviteRewards(owner);
    setOwnerRewards(toEtherNum(ownerRewards));
    const ownerShareAmount = await investingContract.ownerInvestShareAmount();
    setOwnerShareAmount(toEtherNum(ownerShareAmount));
    const contractBalance = await investingTokenContract.balanceOf(investingContract.address);
    setContractBalance(toEtherNum(contractBalance));
    const allUsersInviteRewardAmount = await investingContract.allUsersInviteRewardAmount();
    setAllUsersInviteRewardAmount(toEtherNum(allUsersInviteRewardAmount));
    const estimateOneMonthInvestRewards = await investingContract.estimateOneMonthInvestRewards();
    setEstimateOneMonthInvestRewards(toEtherNum(estimateOneMonthInvestRewards));
    const allUsersUnclaimedRewardsUntillNow = await investingContract.getAllUsersUnclaimedRewardsUntillNow();
    setAllUsersUnclaimedRewardsUntillNow(toEtherNum(allUsersUnclaimedRewardsUntillNow));
    const userTokenBalance = await investingTokenContract.balanceOf(account);
    setUserTokenBalance(toEtherNum(userTokenBalance));

    //store history
    const historyInvestAmount = await investingContract.historyInvestAmount();
    setHistoryInvestAmount(toEtherNum(historyInvestAmount));
    const historyDepositByOwner = await investingContract.historyDepositByOwner();
    setHistoryDepositByOwner(toEtherNum(historyDepositByOwner));
    const historyClaimedAllRewards = await investingContract.historyClaimedAllRewards();
    setHistoryClaimedAllRewards(toEtherNum(historyClaimedAllRewards));
    const historyClaimedInviteRewards = await investingContract.historyClaimedInviteRewards();
    setHistoryClaimedInviteRewards(toEtherNum(historyClaimedInviteRewards));
    const historyClaimedOwnerInviteRewards = await investingContract.historyClaimedOwnerInviteRewards();
    setHistoryClaimedOwnerInviteRewards(toEtherNum(historyClaimedOwnerInviteRewards));
    const historyClaimedInvestRewards = await investingContract.historyClaimedInvestRewards();
    setHistoryClaimedInvestRewards(toEtherNum(historyClaimedInvestRewards));
    const historyWithdrawOwnerShare = await investingContract.historyWithdrawOwnerShare();
    setHistoryWithdrawOwnerShare(toEtherNum(historyWithdrawOwnerShare));
    const historyWithdrawFreeBalance = await investingContract.historyWithdrawFreeBalance();
    setHistoryWithdrawFreeBalance(toEtherNum(historyWithdrawFreeBalance));
    const historyWithdrawEmergency = await investingContract.historyWithdrawEmergency();
    setHistoryWithdrawEmergency(toEtherNum(historyWithdrawEmergency));
    /*
    const investInfo = await investingContract.investInfo(account);
    setCurrentRefer(investInfo[0]);
    setTotalInvestmentAmount(toEtherNum(investInfo[1]));
    setTotalInvestReward(toEtherNum(investInfo[2]));
    setRemainedInvestReward(toEtherNum(investInfo[3]));
    setInvestmentStartTime(Number(investInfo[4]));
    setInvestmentEndTime(Number(investInfo[5]));
    setLastClaimedInvestRewardTime(Number(investInfo[6]));
    setIsActive(investInfo[7]);

    returnTime(Number(investInfo[5]));

    console.log("investInfo", investInfo);
    console.log("refer", investInfo[0]);
    console.log("totalInvestmentAmount", toEtherNum(investInfo[1]));
    console.log("totalInvestReward", toEtherNum(investInfo[2]));
    console.log("remainedInvestReward", toEtherNum(investInfo[3]));
    console.log("investmentStartTime", Number(investInfo[4]));
    console.log("investmentEndTime", Number(investInfo[5]));
    console.log("lastClaimedInvestRewardTime", Number(investInfo[6]));
    console.log("isActive", investInfo[7]);

    const minimumInvestAmount = await investingContract.minimumInvestAmount();
    setMinimumInvestAmount(toEtherNum(minimumInvestAmount));
    console.log("minimumInvestAmount", toEtherNum(minimumInvestAmount));
    const endTimePeriod = await investingContract.endTimePeriod();
    setEndTimePeriod(Number(endTimePeriod));
    console.log("endTimePeriod", Number(endTimePeriod));
    const inviteRewards = await investingContract.inviteRewards(account);
    setInviteRewards(toEtherNum(inviteRewards));
    console.log("inviteRewards", toEtherNum(inviteRewards));
    const allInviteRewards = await investingContract.allInviteRewards(account);
    setAllInviteRewardsOfUser(toEtherNum(allInviteRewards));
    console.log("allInviteRewards", toEtherNum(allInviteRewards));
    const userUnClaimedRewards = await investingContract.getUnclaimedRewards(account);
    setUserUnClaimedRewards(toEtherNum(userUnClaimedRewards));
    console.log("userUnClaimedRewards", toEtherNum(userUnClaimedRewards));
    const userClaimedRewards = await investingContract.userClaimedRewards(account);
    setUserClaimedRewards(toEtherNum(userClaimedRewards));
    console.log("userClaimedRewards", toEtherNum(userClaimedRewards));
    const userTotalInvestRewardsUntillNow = await investingContract.getTotalInvestRewardsUntillNow(account);
    setUserTotalInvestRewardsUntillNow(toEtherNum(userTotalInvestRewardsUntillNow));
    console.log("userClaimedRewards", toEtherNum(userClaimedRewards));
    const userAllRewards = await investingContract.userAllRewards(account);
    setUserAllRewards(toEtherNum(userAllRewards));
    console.log("userAllRewards", toEtherNum(userAllRewards));
    const previousUnclaimedInvestReward = await investingContract.previousUnclaimedInvestReward(account);
    setPreviousUnclaimedInvestReward(toEtherNum(previousUnclaimedInvestReward));
    console.log("previousUnclaimedInvestReward", toEtherNum(previousUnclaimedInvestReward));
    const freezed = await investingContract.freezed(account);
    setFreezed(freezed);
    console.log("freezed", freezed);
    const totalInvestRewardsUntillNow = await investingContract.getTotalInvestRewardsUntillNow(account);
    setTotalInvestRewardsUntillNow(toEtherNum(totalInvestRewardsUntillNow));
    console.log("totalInvestRewardsUntillNow", toEtherNum(totalInvestRewardsUntillNow));
    const isUserActive = checkIsUserActive(toEtherNum(investInfo[1]), Number(investInfo[5]), toEtherNum(allInviteRewards), toEtherNum(totalInvestRewardsUntillNow));
    setIsUserActive(isUserActive);
    console.log("isUserActive", (isUserActive));
    const appIsUnActive = await investingContract.paused();
    setAppIsActive(!appIsUnActive);
    console.log("appIsActive", (!appIsUnActive));
    const userTokenBalance = await investingTokenContract.balanceOf(account);
    setUserTokenBalance(toEtherNum(userTokenBalance));
    console.log("user token balance", toEther(userTokenBalance));
    const owner = await investingContract.owner();
    setOwner(owner);
    console.log("Owner", owner);
    */
  }

  const loadUsers = async () => {
    const allUsers = await investingContract.totalUsers();
    let activeUsers:string[]=[];
    let deActiveUsers:string[]=[];
    let freezedUsers:string[]=[];
    for(const user of allUsers){
        const investInfo = await investingContract.investInfo(user);
        const isActive = investInfo[7];
        const freezed = await investingContract.freezed(user);
        //store active and deactive users
        if(isActive == true){
            activeUsers.push(user);
        } else if(isActive == false){
            deActiveUsers.push(user);
        }
        // store freezed users
        if(freezed == true){
          freezedUsers.push(user);
        }
      }
      //store results in states
        setActiveUsers(activeUsers);
        setDeActiveUsers(deActiveUsers);
        setBlockedUsers(freezedUsers);

  }



  const changeStatus = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = await library.getSigner();
      const isOwner = await checkOwner(investingContract, account, setLoading);
      if (isOwner === false) return;
      
      let res;
      if (status === "ACTIVE") {
        res = await investingContract.connect(signer).pause({ gasLimit: 400000 });
      } else if (status === "STOPPED") {
        res = await investingContract.connect(signer).unpause({ gasLimit: 400000 });
      }
      const receipt = await res.wait();
      if (receipt.status === 1) {
        await LoadInvestData();
        setLoading(false);
        setAlertStatus("success");
        setAlertModal(true);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const withdrawOwnerReward = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = await library.getSigner();
      const isOwner = await checkOwner(investingContract, account, setLoading);
      if (isOwner === false) return;
      if (investingContract) {
        let res;
        res = await investingContract.connect(signer).claimAllReward(
            { gasLimit: 400000 }
          );
        
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          await LoadInvestData();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  const withdrawUserRewardByOwner = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = await library.getSigner();
      const isOwner = await checkOwner(investingContract, account, setLoading);
      if (isOwner === false) return;
      if (investingContract) {
        let res;
        res = await investingContract.connect(signer).claimAllRewardOfUserByOwner(
            infoAddressVal2,
            { gasLimit: 400000 }
          );
        
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          await LoadInvestData();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  const withdrawOwnerShareAmount = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = await library.getSigner();
      const isOwner = await checkOwner(investingContract, account, setLoading);
      if (isOwner === false) return;
      if (investingContract) {
        let res;
        res = await investingContract.connect(signer).withdrawOwnerShareAmount(
            toWei(ownerShareAmountVal.toString()),
            { gasLimit: 400000 }
          );
        
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          await LoadInvestData();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  const withdrawFreeBalance = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = await library.getSigner();
      const isOwner = await checkOwner(investingContract, account, setLoading);
      if (isOwner === false) return;
      
      if(ownerShareAmount + allUsersInviteRewardAmount + allUsersUnclaimedRewardsUntillNow >= contractBalance){
        toast.error(`Contract doesn't have free balance to withdraw!`);
        setLoading(false);
        return;
      }
      if(Number(appVal) > contractBalance - ownerShareAmount - allUsersInviteRewardAmount - allUsersUnclaimedRewardsUntillNow){
        toast.error(`Your amount is more that free balance!`);
        setLoading(false);
        return;
      }
      if (investingContract) {
        let res;
        res = await investingContract.connect(signer).withdrawInsuranceRewardAmountByOwner(
            toWei(appVal.toString()),
            { gasLimit: 400000 }
          );
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          await LoadInvestData();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  const approve = async () => {
    checkConnect(account);
    try {
    if(investingTokenContract){
        setLoading(true);
        
        if (userTokenBalance < Number(depositTokenVal)) {
            toast.error(`Your balance is not enough to deposit! (require: ${depositTokenVal} $)`)
            return setLoading(false);
        } 
        
        
        // const signer = await library.getSigner();
        
        await investingTokenContract.connect(signer).approve(investingContract.address, toWei(depositTokenVal.toString()));
        setLoading(false);
        setIsApproved(true);
        }        
        } catch (error) {
        console.log(error);
        setLoading(false);
        }
  }


  const depositToken = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = await library.getSigner();
      const isOwner = await checkOwner(investingContract, account, setLoading);
      if (isOwner === false) return;
      
      
      if (investingTokenContract) {
        let res;
        res = await investingContract.connect(signer).depositToken(
            toWei(depositTokenVal.toString()),
            { gasLimit: 400000 }
          );
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          setIsApproved(false);
          await LoadInvestData();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


  const withdrawBalance = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = await library.getSigner();
      const isOwner = await checkOwner(investingContract, account, setLoading);
      if (isOwner === false) return;
      
      
      if (investingContract) {
        let res;
        res = await investingContract.connect(signer).withdrawContractBalance(
            toWei(withdrawBalanceVal.toString()),
            { gasLimit: 400000 }
        );
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          await LoadInvestData();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };


//   const withdrawFreeBalance = async () => {
//     checkConnect(account);
//     try {
//       setLoading(true);
//       const signer = await library.getSigner();
//       const isOwner = await checkOwner(stakingV2Contract, account, setLoading);
//       if (isOwner === false) return;
//       if (stakingV2Contract) {
//         let res;
//         res = await stakingV2Contract.connect(signer).withdrawAdmin_usdt(
//           toWei(appVal.toString()),
//           { gasLimit: 400000 }
//         );
//         const receipt = await res.wait();
//         if (receipt.status == 1) {
//           setLoading(false);
//           setAlertStatus("success");
//           setAlertModal(true);
//           await loadData();
//         } else {
//           setLoading(false);
//         }
//       }
//     } catch (error) {
//       console.log(error);
//       setLoading(false);
//     }
//   };

  const changeOwner = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      const isOwner = await checkOwner(investingContract, account, setLoading);
      if (isOwner === false) return;
      if (investingContract) {
        // const signer = library.getSigner();
        let res;
        res = await investingContract.connect(signer).transferOwnership(
          currentOwnerVal.toString(),
          { gasLimit: 400000 }
        );
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          await LoadInvestData();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const blockUser = async (user: string) => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = library.getSigner();
      const isOwner = await checkOwner(investingContract, account, setLoading);
      if (isOwner === false) return;
      if (investingContract) {
        let res;
        res = await investingContract.connect(signer).freezing(user, true, { gasLimit: 400000 });
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          loadUsers();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const unblockUser = async (user: string) => {
    checkConnect(account);
    try {
      setLoading(true);
      const isOwner = await checkOwner(investingContract, account, setLoading);
      if (isOwner === false) return;
      if (investingContract) {
        // const signer = library.getSigner();
        let res;
        res = await investingContract.connect(signer).freezing(user, false, {
          gasLimit: 400000,
        });
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          loadUsers();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const data = [
    {
      label: "Active",
      value: "active",
      desc: <PaginatedActiveUsers itemsPerPage={8} />,
    },
    {
      label: "Deactive",
      value: "deactive",
      desc: <PaginatedDeactiveUsers itemsPerPage={8} />,
    },

    // {
    //   label: "Blocked",
    //   value: "blocked",
    //   desc: <PaginatedBlockedUsers itemsPerPage={8} />,
    // },
  ];

  function Items(props: any) {
    return (
      <>
        {props.currentItems &&
          props.currentItems.map((user: string, key: number) => (
            <div key={key}>
              <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-8 text-white">
                <p className="break-words">{user}</p>
                <button
                  onClick={() => blockUser(user)}
                  className="flex justify-center items-center px-2 py-3 mt-4 md:mt-0 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-600 to-yellow-100 hover:bg-gradient-to-bl rounded-2xl transition-all"
                >
                  Block
                </button>
              </div>
            </div>
          ))}
      </>
    );
  }

  function PaginatedActiveUsers(props: any) {
    const [currentItems, setCurrentItems] = useState<any>([]);
    const [pageCount, setPageCount] = useState(0);

    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      const endOffset = itemOffset + props.itemsPerPage;

      setCurrentItems(activeUsers.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(activeUsers.length / props.itemsPerPage));
    }, [itemOffset, props.itemsPerPage]);

    const handlePageClick = (event: any) => {
      const newOffset =
        (event.selected * props.itemsPerPage) % activeUsers.length;

      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          previousLabel={"← Previous"}
          breakLabel="..."
          nextLabel={"Next →"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </>
    );
  }

  function PaginatedDeactiveUsers(props: any) {
    const [currentItems, setCurrentItems] = useState<any>([]);
    const [pageCount, setPageCount] = useState(0);

    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      const endOffset = itemOffset + props.itemsPerPage;

      setCurrentItems(deActiveUsers.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(deActiveUsers.length / props.itemsPerPage));
    }, [itemOffset, props.itemsPerPage]);

    const handlePageClick = (event: any) => {
      const newOffset =
        (event.selected * props.itemsPerPage) % deActiveUsers.length;

      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          previousLabel={"← Previous"}
          breakLabel="..."
          nextLabel={"Next →"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </>
    );
  }

  function PaginatedBlockedUsers(props: any) {
    const [currentItems, setCurrentItems] = useState<any>([]);
    const [pageCount, setPageCount] = useState(0);

    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      const endOffset = itemOffset + props.itemsPerPage;
      setCurrentItems(blockedUsers.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(blockedUsers.length / props.itemsPerPage));
    }, [itemOffset, props.itemsPerPage]);

    const handlePageClick = (event: any) => {
      const newOffset =
        (event.selected * props.itemsPerPage) % blockedUsers.length;

      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          previousLabel={"← Previous"}
          breakLabel="..."
          nextLabel={"Next →"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </>
    );
  }

  useEffect(() => {
    if (investingContract && tokenContract) {
        LoadInvestData();
    }
  }, [account, investingContract, tokenContract]);

  useEffect(() => {
    if (investingContract) {
      loadUsers();
    }
  }, [investingContract]);

  return (
    <>
      <InvestingHeader />
      <div className="p-10 sm:p-24">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">

          {/*           
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-yellow-400">
            <p>Stake currency</p>
            <h1 className="text-[18px] my-8">{stakeCurrency}</h1>
            <button
              onClick={changeInputCurrency}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-600 to-yellow-100 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Change to {stakeCurrency == "USDT" ? "BNB" : "USDT"}
            </button>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-yellow-400">
            <p>Withdraw currency</p>
            <h1 className="text-[18px] my-8">{withdrawCurrency}</h1>
            <button
              onClick={changeOutputCurrency}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-600 to-yellow-100 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Change to {withdrawCurrency == "USDT" ? "BNB" : "USDT"}
            </button>
          </div>
           */}

          <div className="flex flex-col  items-center p-4 rounded-2xl border border-yellow-400">
            <p>Application status</p>
            <h1 className="text-[18px] my-8">{status}</h1>
            <button
              onClick={changeStatus}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-600 to-yellow-100 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              {status === "ACTIVE" ? "STOP" : "START"}
            </button>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-yellow-400">
            <p>Owner rewards</p>
            <h1 className="text-[18px] my-2">{ownerRewards}$</h1>
            <input
              className="w-full border bg-gray-700 border-yellow-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] mt-2 placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-yellow-500 focus:border-yellow-500"
              type="number"
              value={ownerRewards}
            //   onChange={(e: any) => setOwnerRewardVal(e.target.value)}
            />
            <button
              onClick={withdrawOwnerReward}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-600 to-yellow-100 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Withdraw
            </button>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-yellow-400">
            <p>Owner Share amount</p>
            <h1 className="text-[18px] my-2">{ownerShareAmount}$</h1>
            <input
              className="w-full border bg-gray-700 border-yellow-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] mt-2 placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-yellow-500 focus:border-yellow-500"
              type="number"
              value={ownerShareAmountVal}
              onChange={(e: any) => setOwnerShareAmountVal(e.target.value)}
            />
            <button
              onClick={withdrawOwnerShareAmount}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-600 to-yellow-100 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Withdraw
            </button>
          </div>
          
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-yellow-400">
            <p>Current owner:</p>
            <p className="text-[10px] max-w-full break-words ">{currentOwner ? currentOwner : "0x0"}</p>
            <input
              className="w-full border bg-gray-700 border-yellow-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] mt-2 placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-yellow-500 focus:border-yellow-500"
              type="text"
              value={currentOwnerVal}
              onChange={(e: any) => setCurrentOwnerVal(e.target.value)}
            />
            <button
              onClick={changeOwner}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-600 to-yellow-100 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Change
            </button>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-yellow-400">
            <p>User reward info:</p>
            <p className="text-[10px] max-w-full break-words ">{userAllRewards}</p>
            <input
              className="w-full border bg-gray-700 border-yellow-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] mt-2 placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-yellow-500 focus:border-yellow-500"
              type="text"
              value={infoAddressVal1}
              onChange={(e: any) => setInfoAddressVal1(e.target.value)}
            />
            <button
              onClick={() => getUserRewards(infoAddressVal1)}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-600 to-yellow-100 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              {!userInfoLoading ? "Show" : "Loading..."}
            </button>
            <br/>
            <h6>User Total Invest Amount: {userTotalInvestAmount.toFixed(2)} $</h6>
            {/* <h6>User Total Rewards: {userTotalInvestReward.toFixed(2)} $</h6> */}
            <h6>User Total Rewards: {(userTotalInvestAmount*180/100).toFixed(2)} $</h6>
            <h6>User Current Received Rewards: {(userAllInviteRewards + userTotalInvestRewardsUntillNow + (userTotalInvestAmount*180/100 - userTotalInvestReward)).toFixed(2)} $</h6>
            <h6>User invite rewards: {inviteRewards.toFixed(2)} $</h6>
            <h6>Previous unclaimed rewards: {previousUnclaimedInvestReward.toFixed(2)} $</h6>
            <h6>User All Received Rewards: {userClaimedRewards.toFixed(2)} $</h6>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-yellow-400">
            <p>Withdraw user rewards:</p>
            {/* <p className="text-[10px] max-w-full break-words ">{currentOwner}</p> */}
            <input
              className="w-full border bg-gray-700 border-yellow-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] mt-2 placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-yellow-500 focus:border-yellow-500"
              type="text"
              value={infoAddressVal2}
              onChange={(e: any) => setInfoAddressVal2(e.target.value)}
            />
            <button
              onClick={withdrawUserRewardByOwner}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-600 to-yellow-100 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Withdraw
            </button>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-yellow-400">
            <p>Application free balance</p>
            <h1 className="text-[18px] my-2">{contractBalance - ownerShareAmount - allUsersInviteRewardAmount - allUsersUnclaimedRewardsUntillNow}$</h1>
            
            <input
              className="w-full border bg-gray-700 border-yellow-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] mt-2 placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-yellow-500 focus:border-yellow-500"
              type="number"
              value={appVal}
              onChange={(e: any) => setAppVal(e.target.value)}
            />
            <button
              onClick={withdrawFreeBalance}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-600 to-yellow-100 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Withdraw
            </button><br/>
            <h5>All contract balance: {contractBalance.toFixed(2)} $</h5>
            <h5>Owner share amount: {ownerShareAmount.toFixed(2)} $</h5>
            <h5>Users invite amount: {allUsersInviteRewardAmount.toFixed(2)} $</h5>
            <h5>Users unclaimed rewards: {allUsersUnclaimedRewardsUntillNow.toFixed(2)} $</h5>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-yellow-400">
            <div className="grid">
            <p>rewards for one month:</p>
            <p>(deposit token if it's negative)</p>
            </div>
            <p>{contractBalance - ownerShareAmount - allUsersInviteRewardAmount - estimateOneMonthInvestRewards}</p>$
            {/* <p className="text-[10px] max-w-full break-words ">{currentOwner}</p> */}
            <input
              className="w-full border bg-gray-700 border-yellow-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] mt-2 placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-yellow-500 focus:border-yellow-500"
              type="text"
              value={depositTokenVal}
              onChange={(e: any) => setDepositTokenVal(e.target.value)}
            />
            { !isApproved ? <button
            type="button"
            onClick={() => approve()}
            // disabled={true}
            className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-600 to-yellow-100 hover:bg-gradient-to-bl rounded-2xl transition-all"
          >
            Approve
          </button> : null}
            <button
              onClick={depositToken}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-600 to-yellow-100 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Deposit
            </button><br/>
            <h5>All contract balance: {contractBalance.toFixed(2)} $</h5>
            <h5>Owner share amount: {ownerShareAmount.toFixed(2)} $</h5>
            <h5>Users invite amount: {allUsersInviteRewardAmount.toFixed(2)} $</h5>
            <h5>One month rewards: {estimateOneMonthInvestRewards.toFixed(2)} $</h5>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-red-400">
            <div className="grid">
            <p>All app balance:</p>
            <p>(Only in necessary condition)</p>
            </div>
            <p>{contractBalance}</p>$
            {/* <p className="text-[10px] max-w-full break-words ">{currentOwner}</p> */}
            <input
              className="w-full border bg-gray-700 border-yellow-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] mt-2 placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-yellow-500 focus:border-yellow-500"
              type="text"
              value={withdrawBalanceVal}
              onChange={(e: any) => setWithdrawBalanceVal(e.target.value)}
            />
            <button
              onClick={withdrawBalance}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-red-600 to-red-300 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Withdraw
            </button>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-yellow-400">
            <h5>All Investment amount: {historyInvestAmount.toFixed(2)} $</h5>
            <h5>All Reward amount: {(historyInvestAmount*1.8).toFixed(2)} $</h5>
            <h5>All Received rewards: {(historyClaimedAllRewards - historyClaimedOwnerInviteRewards).toFixed(2)} $</h5>
            <h5>Remain Rewards to pay: {(historyInvestAmount*1.8 - historyClaimedAllRewards + historyClaimedOwnerInviteRewards).toFixed(2)} $</h5>
            <h5>Deposit of owner: {historyDepositByOwner.toFixed(2)} $</h5>
            {/* <h5>Users unclaimed rewards: {allUsersUnclaimedRewardsUntillNow.toFixed(2)} $</h5> */}
          </div>
        </div>
        <div className="mt-24">
          <div className="px-4 py-8 border border-yellow-400 rounded-lg">
            <div className="flex justify-center items-center mb-8 flex-wrap">
              <input
                className="border bg-gray-700 border-yellow-600 rounded-2xl w-full sm:w-auto p-[12px] pl-[20px] pr-[40px] placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-yellow-500 focus:border-yellow-500"
                type="text"
                value={blockedSearch}
                onChange={(e: any) => setBlockedSearch(e.target.value)}
              />
              <button
                onClick={() => blockUser(blockedSearch)}
                type="button"
                className="flex justify-center items-center self-stretch px-2 py-3 mt-4 w-full sm:mt-0 sm:w-auto sm:ml-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-600 to-yellow-100 hover:bg-gradient-to-bl rounded-2xl transition-all"
              >
                Block
              </button>
            </div>

            <Tabs value="active">
              <TabsHeader
                placeholder=""
                onPointerEnterCapture={() => {}}
                onPointerLeaveCapture={() => {}}
              >
                {data.map(({ label, value }) => (
                  <Tab
                    key={value}
                    value={value}
                    placeholder=""
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                  >
                    {label}
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                {data.map(({ value, desc }) => (
                  <TabPanel key={value} value={value}>
                    {desc}
                  </TabPanel>
                ))}
              </TabsBody>
            </Tabs>
          </div>
          <div className="p-8 border border-yellow-400 rounded-lg mt-24">
            <p className="m-8 text-[18px]">Blocked users</p>
            {blockedUsers.map((user: string, key: number) => (
              <div key={key}>
                <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-8">
                  <p className="break-words">{user}</p>
                  <button
                    onClick={() => unblockUser(user)}
                    className="flex justify-center items-center px-2 py-3 mt-4 md:mt-0 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-600 to-yellow-100 hover:bg-gradient-to-bl rounded-2xl transition-all"
                  >
                    Unblock
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        {loading ? <Loading loading={loading} setLoading={setLoading} /> : null}
        {alertModall ? (
          <AlertModal
            alertModal={alertModall}
            status={alertStatus}
            setAlertModal={setAlertModal}
            setStatus={setAlertStatus}
          />
        ) : null}
      </div>
    </>
  );
};

export default InvestingPanel;
