import "./App.css";
import { Routes, Route, useSearchParams } from "react-router-dom";
import Header from "./components/Header";
import HomePage from "./components/HomePage";
import Footer from "./components/Footer";
import Panel from "./components/Panel";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAccount,
  setChainId,
  setInvestingContract,
  setInvestingTokenContract,
  setLibrary,
  setShouldDisconnect,
  setShouldLoad,
  setStakingContract,
  setStakingV2Contract,
  setTokenContract,
} from "./features/dapp/dappSlice";
import { ethers, Signer } from "ethers";
import { investingAbi, InvestingAddress, InvestingUsdtAddress, stakingAbi, StakingAddress, stakingV2Abi, StakingV2Address, usdtAbi, UsdtAddress } from "./utils/Abi";
import { useWeb3React } from "@web3-react/core";
import { bscConnector, injected, walletConnect } from "./components/Connectors";
import { web3Modal } from "./components/Web3Modal";
import PanelV2 from "./components/PanelV2";
import InvestingHomePage from "./components/investing/InvestingHomePage";
import InvestingPanel from "./components/investing/InvestingPanel";
import { useAddress, useChainId, useNetworkMismatch, useSDK, useSigner, useSwitchChain } from "@thirdweb-dev/react";
import { Binance } from "@thirdweb-dev/chains";
import More from "./components/about/More";
import Production from "./components/about/Production";
import Financial from "./components/about/Financial";
import InvestmentInfo from "./components/about/Investment";
import Educational from "./components/about/Educational";
import Distribution from "./components/about/Distribution";

function App() {
  const dispatch = useDispatch();

  const signer = useSigner();
  const accountAddress = useAddress();
  const currentChainId = useChainId();
  const isMismatched = useNetworkMismatch();
  
  const switchChain = useSwitchChain();

  useEffect(() => {
    if(currentChainId){
      dispatch(setChainId(currentChainId as any));
    }
    console.log("chainId", currentChainId, isMismatched)
    if(currentChainId && currentChainId !== 56 && currentChainId !== 11155111){
      console.log("HEELLO")
      switchChain(Binance.chainId);
    }
  },[currentChainId])




  const library = useSelector((state: any) => state.counter.library);
  const account = useSelector((state: any) => state.counter.account);
  const chainId = useSelector((state: any) => state.counter.chainId);
  const version = useSelector((state: any) => state.counter.version);

  const loadConnect = async () => {
    try {
    if(signer){
    // const wallet = localStorage.getItem("khfedu_Wallet");
    // if (wallet && !library) {
      // const provider = await web3Modal.connectTo(wallet as string);
      const library = signer?.provider;
      dispatch(setLibrary(library as any));
      // const signer = library.getSigner();
      // const account = await signer.getAddress();
      if(accountAddress){
      dispatch(setAccount(accountAddress as any));
      }
      //set conracts
      // const { chainId } = await library?.getNetwork();
      // dispatch(setChainId(chainId));
      const usdtContract = new ethers.Contract(UsdtAddress(currentChainId), usdtAbi, library);
      const stakingContract = new ethers.Contract(
        StakingAddress(currentChainId),
        stakingAbi,
        library
      );
      const stakingV2Contract = new ethers.Contract(
        StakingV2Address(currentChainId),
        stakingV2Abi,
        library
      );
      
      const investingTokenContract = new ethers.Contract(
        InvestingUsdtAddress(currentChainId),
        usdtAbi,
        library
      );
      const investingContract = new ethers.Contract(
        InvestingAddress(currentChainId),
        investingAbi,
        library
      );
      
      if (stakingContract) {
        dispatch(setStakingContract(stakingContract as any));
      }
      if (stakingV2Contract) {
        dispatch(setStakingV2Contract(stakingV2Contract as any));
      }
      if (usdtContract) {
        dispatch(setTokenContract(usdtContract as any));
      }
      
      if (investingTokenContract) {
        dispatch(setInvestingTokenContract(investingTokenContract as any));
      }
      if (investingContract) {
        dispatch(setInvestingContract(investingContract as any));
      }
      
    }else{
      disconnect()
    }
    
  } catch (error) {
      console.log("Load data error:", error)
  }
  
  };


  const loadContract = async () => {
    try {
      
    if (signer) {
      dispatch(setLibrary(signer?.provider  as any));
      // const signer = library.getSigner();
      // const account = await signer.getAddress();
      if(accountAddress){
      dispatch(setAccount(accountAddress  as any));
      }
      //set conracts
      // const { chainId } = await library?.getNetwork();
      // dispatch(setChainId(chainId));
      const usdtContract = new ethers.Contract(UsdtAddress(currentChainId), usdtAbi, library);
      const stakingContract = new ethers.Contract(
        StakingAddress(currentChainId),
        stakingAbi,
        library
      );
      const stakingV2Contract = new ethers.Contract(
        StakingV2Address(currentChainId),
        stakingV2Abi,
        library
      );
      const investingTokenContract = new ethers.Contract(
        InvestingUsdtAddress(currentChainId),
        usdtAbi,
        library
      );
      const investingContract = new ethers.Contract(
        InvestingAddress(currentChainId),
        investingAbi,
        library
      );
      if (stakingContract) {
        dispatch(setStakingContract(stakingContract  as any));
      }
      if (stakingV2Contract) {
        dispatch(setStakingV2Contract(stakingV2Contract  as any));
      }
      if (usdtContract) {
        dispatch(setTokenContract(usdtContract  as any));
      }
      if (investingTokenContract) {
        dispatch(setInvestingTokenContract(investingTokenContract  as any));
      }
      if (investingContract) {
        dispatch(setInvestingContract(investingContract  as any));
      }
    }else{
      disconnect()
    }
    
    } catch (error) {
        console.log("load contracts error:", error)
    }
  };

  async function disconnect() {
    if(!signer){
      try {
      // localStorage.removeItem("khfedu_Wallet");
      // web3Modal.clearCachedProvider();
      dispatch(setAccount(''  as any));
      dispatch(setLibrary(null as any));
      dispatch(setChainId(null as any));
      dispatch(setTokenContract(null as any));
      dispatch(setStakingContract(null as any));
      dispatch(setStakingV2Contract(null as any));
      dispatch(setInvestingTokenContract(null as any));
      dispatch(setInvestingContract(null as any));
      dispatch(setShouldLoad(false as any));
      dispatch(setShouldDisconnect(false as any));
    } catch (error) {
      console.log("disconnect error:", error)
    }
    }

  }

  // useEffect(() => {
  //   if(library){
  //     const provider = library;
  //     provider.on("accountsChanged", (accounts: string[]) => {
  //       loadContract()
  //     });
  //     provider.on("chainChanged", (chainId: number) => {
  //       loadContract();
  //     });
  //   }
  // })

  // useEffect(() => {
  //   if(library){
  //   const provider = library.provider;
  //   // provider.on("disconnect", (error: { code: number; message: string }) => {
  //   //   disconnect();
  //   //   console.log(error);
  //   // });
  //   provider.on("disconnect", disconnect)
  // }
  // })

  const changeNetwork = async () => {
    const wallet = localStorage.getItem("khfedu_Wallet")
    if(window.ethereum && wallet == "injected" && library && chainId !== 56){
        console.log("changeNetwork")
    window.ethereum.request({
        method: "wallet_addEthereumChain",
        params: [{
            chainId: "0x38",
            rpcUrls: ["https://bsc-dataseed1.binance.org"],
            chainName: "Binance Smart Chain Mainnet",
            nativeCurrency: {
                name: "BNB",
                symbol: "BNB",
                decimals: 18
            },
            blockExplorerUrls: ["https://bscscan.com"]
        }]
    });
  }
}


  useEffect(() => {
    loadContract()
    // loadConnect();
    // changeNetwork();
  }, [signer, accountAddress, currentChainId]);

  return (
    <div className="overflow-x-clip text-gray-50">
      {/* <Header /> */}
      <ToastContainer
        position="top-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        theme="dark"
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/investing" element={<InvestingHomePage />} />
        <Route path="/panel" element={version == 1 ? <Panel /> : <PanelV2/>} />
        <Route path="/investing/panel" element={<InvestingPanel/>} />
        <Route path="/about-us" element={<Footer />} />
        <Route path="/educational" element={<Educational />} />
        <Route path="/investmentInfo" element={<InvestmentInfo />} />
        <Route path="/financial" element={<Financial />} />
        <Route path="/production" element={<Production />} />
        <Route path="/distribution" element={<Distribution />} />
        <Route path="/more" element={<More />} />
      </Routes>
      {/* <Footer /> */}
    </div>
  );
}

export default App;
