import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setShouldLoad } from "../features/dapp/dappSlice";
import {
  checkAppIsActive,
  checkConnect,
  checkIsShouldStake,
  checkIsStakeAble,
  getRefer,
  getStakeAmount,
  hasEnoughBalance,
} from "../hooks/Errors";
import coin from "../images/coin.png";
import logo from "../images/logo.png";
import logoG from "../images/logo-gray.png";

import { StakingDetails } from "../types/Stake";
import { toWei } from "../utils/utils";
import AlertModal from "./AlertModal";
import Loading from "./Loading";
import { useSigner } from "@thirdweb-dev/react";

export default function Stake() {
  const [loading, setLoading] = useState(false);
  const [alertModall, setAlertModal] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const [shouldStaked, setShouldStaked] = useState(true);

  const [rank, setRank] = useState(0);
  const [stakeAmount, setStakeAmount] = useState(30);
  const [stakeFee, setStakeFee] = useState(10);
  const [totalStakeAmount, setTotalStakeAmount] = useState(10);
  const [stakeCurrency, setStakeCurrency] = useState("USDT");
  const [payAmount, setPayAmount] = useState<any>('');
  const [isApproved, setIsApproved] = useState(false);

  const signer = useSigner()


  const dispatch = useDispatch();

  const account = useSelector((state: any) => state.counter.account);
  const library = useSelector((state: any) => state.counter.library);
  const tokenContract = useSelector(
    (state: any) => state.counter.tokenContract
  );
  const stakingContract = useSelector(
    (state: any) => state.counter.stakingContract
  );

  const loadData = async () => {
    // console.log(await library.getBalance(account))
    const details = await getStakeAmount(stakingContract, account);
    const shouldStake = await checkIsShouldStake(stakingContract, account);
    setShouldStaked(shouldStake);
    setRank(details?.rank);
    setStakeAmount(details?.stakeAmount as number);
    setStakeFee(details?.fee as number);
    setTotalStakeAmount(details?.totalAmount as number);
    setStakeCurrency(details?.stakeCurrency as string);
    setPayAmount(details?.payAmount)
  };

  const approve = async () => {
    console.log("HHH")
    checkConnect(account);
    if(tokenContract){
      setLoading(true);
      const appIsActive = await checkAppIsActive(stakingContract);
        if(appIsActive==false) return setLoading(false);
        const stakeDetails = await getStakeAmount(stakingContract, account);
        const hasBalance = await hasEnoughBalance(
          stakingContract,
          tokenContract,
          library,
          account,
          totalStakeAmount
        );
        if (hasBalance === false) return setLoading(false);
        const refer = await getRefer(
          stakingContract,
          account,
          window.location.href
        );
        
        if (refer?.isCorrect === false) return setLoading(false);
        const isStakeable = await checkIsStakeAble(stakingContract, account);
        if (isStakeable === false) return setLoading(false);
        if (stakeDetails?.stakeCurrency === "USDT") {
        // const signer = await library.getSigner();
        await tokenContract.connect(signer).approve(stakingContract.address, toWei(stakeDetails.totalAmount.toString()));
        setLoading(false);
        setIsApproved(true);
        }
    }
  }

  const stake = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      if (stakingContract) {
        const appIsActive = await checkAppIsActive(stakingContract);
        if(appIsActive==false) return setLoading(false);
        const stakeDetails = await getStakeAmount(stakingContract, account);
        const hasBalance = await hasEnoughBalance(
          stakingContract,
          tokenContract,
          library,
          account,
          totalStakeAmount
        );
        if (hasBalance === false) return setLoading(false);
        const refer = await getRefer(
          stakingContract,
          account,
          window.location.href
        );
        
        if (refer?.isCorrect === false) return setLoading(false);
        const isStakeable = await checkIsStakeAble(stakingContract, account);
        if (isStakeable === false) return setLoading(false);
        let res;
        if(stakeDetails?.stakeCurrency === "USDT" && isApproved ===false){toast.error("Please First approve");setLoading(false);return}
        if (stakeDetails?.stakeCurrency === "USDT") {
          // const signer = await library.getSigner();
          // await tokenContract.connect(signer).approve(stakingContract.address, toWei(stakeDetails.totalAmount.toString()));
          res = await stakingContract.connect(signer).stakeUsdt(refer?.address, {
            gasLimit: 400000,
          });
        }
        if (stakeDetails?.stakeCurrency === "BNB") {
          // const signer = await library.getSigner();
          const details = await getStakeAmount(stakingContract, account);
          const bnbAmount = await stakingContract.getAmountEthOut(toWei(details?.totalAmount.toString() as string));
          res = await stakingContract.connect(signer).stakeEth(refer?.address, {
            value:bnbAmount,
            gasLimit: 400000,
          });
        }
        const receipt = await res.wait();
        if (receipt.status == 1) {
          await loadData();
          dispatch(setShouldLoad(true as any));
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (stakingContract && account) {
      loadData();
    }
    if(!stakingContract || !account){
      setShouldStaked(true);
    setRank(0);
    setStakeAmount(30);
    setStakeFee(10);
    setTotalStakeAmount(33);
    setPayAmount(33)
    setStakeCurrency("USDT")
    }
  }, [account, stakingContract]);

  return (
    <div
      className="w-[90%] sm:w-10/12 px-3 py-20 my-24 mx-auto border border-solid rounded-md"
      id="Stake"
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        <div className="flex items-center justify-center lg:justify-start px-7">
          <img src={shouldStaked ? logoG :logo} alt="Coin Image" />
        </div>
        <div className="flex flex-col justify-center px-7 order-first lg:order-last mb-8 lg:mb-0">
          <h1 className="text-[25px] font-[600]">Active</h1>
          <p className="mt-5">
            Your rank is {rank} so you should register {stakeAmount}$ + {stakeFee}%
            fee.
          </p>
          <p className="mt-5"><b>Active Currency:</b> {stakeCurrency} </p>
          <p className="mt-5"><b>You should pay:</b> {payAmount} {stakeCurrency} </p>

          {stakeCurrency === "USDT" && !isApproved ? <button
            type="button"
            onClick={approve}
            // disabled={true}
            className="flex justify-center items-center p-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-900 to-blue-200 hover:bg-gradient-to-bl rounded-lg transition-all"
          >
            Approve
          </button> : null}
          <button
            type="button"
            onClick={stake}
            className="flex justify-center items-center p-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-lg transition-all"
          >
            Active
          </button>
        </div>
      </div>
      {loading ? <Loading loading={loading} setLoading={setLoading} /> : null}
      {alertModall ? (
        <AlertModal
          alertModal={alertModall}
          status={alertStatus}
          setAlertModal={setAlertModal}
          setStatus={setAlertStatus}
        />
      ) : null}
    </div>
  );
}
