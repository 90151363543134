import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  applicationStatus,
  checkConnect,
  checkOwner,
  getActiveUsers,
  getBlockedUsers,
  getContractFreeBalance,
  getDeActiveUsers,
  getInputCurrency,
  getOutputCurrency,
  getUserReward,
} from "../hooks/Errors";
import { toEtherNum, toWei } from "../utils/utils";
import AlertModal from "./AlertModal";
import Loading from "./Loading";
import ReactPaginate from "react-paginate";
import {
  Tabs,
  TabsHeader,
  TabsBody,
  Tab,
  TabPanel,
} from "@material-tailwind/react";
import Header from "./Header";
import { useSigner } from "@thirdweb-dev/react";

const PanelV2 = () => {
  const [loading, setLoading] = useState(false);
  const [alertModall, setAlertModal] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const [stakeCurrency, setStakeCurrency] = useState("USDT");
  const [withdrawCurrency, setWithdrawCurrency] = useState("USDT");
  const [status, setStatus] = useState("ACTIVE");
  const [ownerRewards, setOwnerRewards] = useState(0);
  const [freeBalance, setFreeBalance] = useState(0);
  const [contractBalance, setContractBalance] = useState(0);
  const [allContractBalance, setAllContractBalance] = useState(0);
  const [allRewardsBalance, setAllRewardsBalance] = useState(0);
  const [currentOwner, setCurrentOwner] = useState("");
  const [activeUsers, setActiveUsers] = useState<any>([]);
  const [deActiveUsers, setDeActiveUsers] = useState<any>([]);
  const [blockedUsers, setBlockedUsers] = useState<any>([]);

  const [ownerRewardVal, setOwnerRewardVal] = useState("");
  const [appVal, setAppVal] = useState("");
  const [currentOwnerVal, setCurrentOwnerVal] = useState("");
  const [blockedSearch, setBlockedSearch] = useState("");

  const [infoAddressVal1, setInfoAddressVal1] = useState("");
  const [infoAddressVal2, setInfoAddressVal2] = useState("");
  const [speceficUserRewards, setSpeceficUserRewards] = useState("");
  const [userInfoLoading , setUserInfoLoading] = useState(false);
  const [withdrawBalanceVal, setWithdrawBalanceVal] = useState("");

  const signer = useSigner()


  const account = useSelector((state: any) => state.counter.account);
  const library = useSelector((state: any) => state.counter.library);

  const tokenContract = useSelector(
    (state: any) => state.counter.tokenContract
  );
  const stakingContract = useSelector(
    (state: any) => state.counter.stakingContract
  );
  const stakingV2Contract = useSelector(
    (state: any) => state.counter.stakingV2Contract
  );

  const loadData = async () => {
    const stakeCurrency = await getInputCurrency(stakingV2Contract);
    setStakeCurrency(stakeCurrency as string);
    const withdrawCurrency = await getOutputCurrency(stakingV2Contract);
    setWithdrawCurrency(withdrawCurrency as string);
    const status = await applicationStatus(stakingV2Contract);
    setStatus(status as string);
    const ownerRewards:any = await getUserReward(stakingV2Contract, account);
    setOwnerRewards(ownerRewards as number);
    const currentOwner = await stakingV2Contract.owner();
    const contractDetail = await getContractFreeBalance(
      stakingV2Contract,
      tokenContract
    );
    console.log('sss:', contractDetail)
    setFreeBalance(contractDetail?.freeBalance as number);
    setAllContractBalance(contractDetail?.contractBalance as number);
    setAllRewardsBalance(contractDetail?.allPendingRewards as number);
    setCurrentOwner(currentOwner);
  };

  const loadUsers = async () => {
    const allActiveUsers = await getActiveUsers(stakingV2Contract);
    setActiveUsers(allActiveUsers);
    const allDeActiveUsers = await getDeActiveUsers(stakingV2Contract);
    setDeActiveUsers(allDeActiveUsers);
    console.log('deactiveUsers:', allDeActiveUsers)
    const allBlockedUsers = await getBlockedUsers(stakingV2Contract);
    setBlockedUsers(allBlockedUsers);
  };

  const getSpeceficUserRewards = async (user:string) => {
    setUserInfoLoading(true);
    const userRewards = await getUserReward(stakingV2Contract, user);
    setSpeceficUserRewards(Number(userRewards).toString());
    setUserInfoLoading(false);
  } 

  const changeInputCurrency = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = await library.getSigner();
      const isOwner = await checkOwner(stakingV2Contract, account, setLoading);
      if (isOwner === false) return;
      const inputCurrency = await getInputCurrency(stakingV2Contract);
      let res;
      if (inputCurrency === "USDT") {
        res = await stakingV2Contract.connect(signer).setInputType(1, { gasLimit: 400000 });
      } else if (inputCurrency === "BNB") {
        res = await stakingV2Contract.connect(signer).setInputType(0, { gasLimit: 400000 });
      }
      const receipt = await res.wait();
      if (receipt.status == 1) {
        setLoading(false);
        setAlertStatus("success");
        setAlertModal(true);
        await loadData();
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const changeOutputCurrency = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = await library.getSigner();
      const isOwner = await checkOwner(stakingV2Contract, account, setLoading);
      if (isOwner === false) return;
      const outputCurrency = await getOutputCurrency(stakingV2Contract);
      let res;
      if (outputCurrency === "USDT") {
        res = await stakingV2Contract.connect(signer).setOutputType(1, { gasLimit: 400000 });
      } else if (outputCurrency === "BNB") {
        res = await stakingV2Contract.connect(signer).setOutputType(0, { gasLimit: 400000 });
      }
      const receipt = await res.wait();
      if (receipt.status == 1) {
        setLoading(false);
        setAlertStatus("success");
        setAlertModal(true);
        await loadData();
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const changeStatus = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = await library.getSigner();
      const isOwner = await checkOwner(stakingV2Contract, account, setLoading);
      if (isOwner === false) return;
      const currentStatus = await applicationStatus(stakingV2Contract);
      let res;
      if (currentStatus === "ACTIVE") {
        res = await stakingV2Contract.connect(signer).pause({ gasLimit: 400000 });
      } else if (currentStatus === "STOPPED") {
        res = await stakingV2Contract.connect(signer).unpause({ gasLimit: 400000 });
      }
      const receipt = await res.wait();
      if (receipt.status === 1) {
        await loadData();
        setLoading(false);
        setAlertStatus("success");
        setAlertModal(true);
      } else {
        setLoading(false);
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const withdrawReward = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = await library.getSigner();
      const isOwner = await checkOwner(stakingV2Contract, account, setLoading);
      if (isOwner === false) return;
      if (stakingV2Contract) {
        const rewardCurrency = await stakingV2Contract.outputToken();
        let res;
        if (rewardCurrency === 0) {
          res = await stakingV2Contract.connect(signer).withdrawRewardsUsdt(
            toWei(ownerRewardVal.toString()),
            { gasLimit: 400000 }
          );
        }
        if (rewardCurrency === 1) {
          res = await stakingV2Contract.connect(signer).withdrawRewardsEther(
            toWei(ownerRewardVal.toString()),
            { gasLimit: 400000 }
          );
        }
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          await loadData();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const withdrawFreeBalance = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = await library.getSigner();
      const isOwner = await checkOwner(stakingV2Contract, account, setLoading);
      if (isOwner === false) return;
      if (stakingV2Contract) {
        let res;
        res = await stakingV2Contract.connect(signer).withdrawAdmin_usdt(
          toWei(appVal.toString()),
          { gasLimit: 400000 }
        );
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          await loadData();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const withdrawBalance = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = await library.getSigner();
      const isOwner = await checkOwner(stakingV2Contract, account, setLoading);
      if (isOwner === false) return;
      
      
      if (stakingV2Contract) {
        let res;
        res = await stakingV2Contract.connect(signer).withdrawAdmin_usdt_all(
            toWei(withdrawBalanceVal.toString()),
            { gasLimit: 400000 }
        );
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          await loadData();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const changeOwner = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      const isOwner = await checkOwner(stakingV2Contract, account, setLoading);
      if (isOwner === false) return;
      if (stakingV2Contract) {
        // const signer = library.getSigner();
        let res;
        res = await stakingV2Contract.connect(signer).transferOwnership(
          currentOwnerVal.toString(),
          { gasLimit: 400000 }
        );
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          await loadData();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const blockUser = async (user: string) => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = library.getSigner();
      const isOwner = await checkOwner(stakingV2Contract, account, setLoading);
      if (isOwner === false) return;
      if (stakingV2Contract) {
        let res;
        res = await stakingV2Contract.connect(signer).freezing(user, true, { gasLimit: 400000 });
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          loadUsers();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const unblockUser = async (user: string) => {
    checkConnect(account);
    try {
      setLoading(true);
      const isOwner = await checkOwner(stakingV2Contract, account, setLoading);
      if (isOwner === false) return;
      if (stakingV2Contract) {
        // const signer = library.getSigner();
        let res;
        res = await stakingV2Contract.connect(signer).freezing(user, false, {
          gasLimit: 400000,
        });
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          loadUsers();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const withdrawUserRewardByOwner = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      // const signer = await library.getSigner();
      const isOwner = await checkOwner(stakingV2Contract, account, setLoading);
      if (isOwner === false) return;
      if (stakingV2Contract) {
        let res;
        const userReward = await stakingV2Contract.pendingRewards(infoAddressVal2);
        res = await stakingV2Contract.connect(signer).withdrawRewardsUsdtByOwnerForUser(
            infoAddressVal2,
            userReward,
            { gasLimit: 400000 }
          );
        
        const receipt = await res.wait();
        if (receipt.status == 1) {
          setLoading(false);
          setAlertStatus("success");
          setAlertModal(true);
          await loadData();
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const data = [
    {
      label: "Active",
      value: "active",
      desc: <PaginatedActiveUsers itemsPerPage={8} />,
    },
    {
      label: "Deactive",
      value: "deactive",
      desc: <PaginatedDeactiveUsers itemsPerPage={8} />,
    },

    // {
    //   label: "Blocked",
    //   value: "blocked",
    //   desc: <PaginatedBlockedUsers itemsPerPage={8} />,
    // },
  ];

  function Items(props: any) {
    return (
      <>
        {props.currentItems &&
          props.currentItems.map((user: string, key: number) => (
            <div key={key}>
              <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-8 text-white">
                <p className="break-words">{user}</p>
                <button
                  onClick={() => blockUser(user)}
                  className="flex justify-center items-center px-2 py-3 mt-4 md:mt-0 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-2xl transition-all"
                >
                  Block
                </button>
              </div>
            </div>
          ))}
      </>
    );
  }

  function PaginatedActiveUsers(props: any) {
    const [currentItems, setCurrentItems] = useState<any>([]);
    const [pageCount, setPageCount] = useState(0);

    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      const endOffset = itemOffset + props.itemsPerPage;

      setCurrentItems(activeUsers.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(activeUsers.length / props.itemsPerPage));
    }, [itemOffset, props.itemsPerPage]);

    const handlePageClick = (event: any) => {
      const newOffset =
        (event.selected * props.itemsPerPage) % activeUsers.length;

      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          previousLabel={"← Previous"}
          breakLabel="..."
          nextLabel={"Next →"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </>
    );
  }

  function PaginatedDeactiveUsers(props: any) {
    const [currentItems, setCurrentItems] = useState<any>([]);
    const [pageCount, setPageCount] = useState(0);

    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      const endOffset = itemOffset + props.itemsPerPage;

      setCurrentItems(deActiveUsers.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(deActiveUsers.length / props.itemsPerPage));
    }, [itemOffset, props.itemsPerPage]);

    const handlePageClick = (event: any) => {
      const newOffset =
        (event.selected * props.itemsPerPage) % deActiveUsers.length;

      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          previousLabel={"← Previous"}
          breakLabel="..."
          nextLabel={"Next →"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </>
    );
  }

  function PaginatedBlockedUsers(props: any) {
    const [currentItems, setCurrentItems] = useState<any>([]);
    const [pageCount, setPageCount] = useState(0);

    const [itemOffset, setItemOffset] = useState(0);

    useEffect(() => {
      const endOffset = itemOffset + props.itemsPerPage;
      setCurrentItems(blockedUsers.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(blockedUsers.length / props.itemsPerPage));
    }, [itemOffset, props.itemsPerPage]);

    const handlePageClick = (event: any) => {
      const newOffset =
        (event.selected * props.itemsPerPage) % blockedUsers.length;

      setItemOffset(newOffset);
    };

    return (
      <>
        <Items currentItems={currentItems} />
        <ReactPaginate
          previousLabel={"← Previous"}
          breakLabel="..."
          nextLabel={"Next →"}
          pageCount={pageCount}
          onPageChange={handlePageClick}
          containerClassName={"pagination"}
          previousLinkClassName={"pagination__link"}
          nextLinkClassName={"pagination__link"}
          disabledClassName={"pagination__link--disabled"}
          activeClassName={"pagination__link--active"}
        />
      </>
    );
  }

  useEffect(() => {
    if (stakingV2Contract && tokenContract) {
      loadData();
    }
  }, [account, stakingV2Contract, tokenContract]);

  useEffect(() => {
    if (stakingV2Contract) {
      loadUsers();
    }
  }, [stakingV2Contract]);

  return (
    <>
      <Header />
      <div className="p-10 sm:p-24">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-16">
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-blue-400">
            <p>Stake currency</p>
            <h1 className="text-[18px] my-8">{stakeCurrency}</h1>
            <button
              onClick={changeInputCurrency}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Change to {stakeCurrency == "USDT" ? "BNB" : "USDT"}
            </button>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-blue-400">
            <p>Withdraw currency</p>
            <h1 className="text-[18px] my-8">{withdrawCurrency}</h1>
            <button
              onClick={changeOutputCurrency}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Change to {withdrawCurrency == "USDT" ? "BNB" : "USDT"}
            </button>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-blue-400">
            <p>Application status</p>
            <h1 className="text-[18px] my-8">{status}</h1>
            <button
              onClick={changeStatus}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              {status === "ACTIVE" ? "STOP" : "START"}
            </button>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-blue-400">
            <p>Owner rewards</p>
            <h1 className="text-[18px] my-2">{ownerRewards}$</h1>
            <input
              className="w-full border bg-gray-700 border-gray-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] mt-2 placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
              type="number"
              value={ownerRewardVal}
              onChange={(e: any) => setOwnerRewardVal(e.target.value)}
            />
            <button
              onClick={withdrawReward}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Withdraw
            </button>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-blue-400">
            <p>Application free balance</p>
            <h1 className="text-[18px] my-2">{freeBalance}$</h1>
            
            <input
              className="w-full border bg-gray-700 border-gray-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] mt-2 placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
              type="number"
              value={appVal}
              onChange={(e: any) => setAppVal(e.target.value)}
            />
            <button
              onClick={withdrawFreeBalance}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Withdraw
            </button><br/>
            <h5>All contract balance: {allContractBalance} $</h5>
            <h5>All Pending rewards: {allRewardsBalance} $</h5>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-blue-400">
            <p>Current owner:</p>
            <p className="text-[10px] max-w-full break-words ">{currentOwner}</p>
            {/* <h3 className="text-[18px] my-2">{`${currentOwner.slice(
              0,
              5
            )} .... ${currentOwner.slice(-7, -1)}`}</h3> */}
            <input
              className="w-full border bg-gray-700 border-gray-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] mt-2 placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
              type="text"
              value={currentOwnerVal}
              onChange={(e: any) => setCurrentOwnerVal(e.target.value)}
            />
            <button
              onClick={changeOwner}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Change
            </button>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-blue-400">
            <p>User reward info:</p>
            <p className="text-[10px] max-w-full break-words ">{speceficUserRewards}</p>
            <input
              className="w-full border bg-gray-700 border-blue-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] mt-2 placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
              type="text"
              value={infoAddressVal1}
              onChange={(e: any) => setInfoAddressVal1(e.target.value)}
            />
            <button
              onClick={() => getSpeceficUserRewards(infoAddressVal1)}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              {!userInfoLoading ? "Show" : "Loading..."}
            </button>
            <br/>
            {/* <h6>User Total Invest Amount: {userTotalInvestAmount.toFixed(2)} $</h6> */}
            {/* <h6>User Total Rewards: {userTotalInvestReward.toFixed(2)} $</h6> */}
            {/* <h6>User Total Rewards: {(userTotalInvestAmount*180/100).toFixed(2)} $</h6> */}
            {/* <h6>User Current Received Rewards: {(userAllInviteRewards + userTotalInvestRewardsUntillNow + (userTotalInvestAmount*180/100 - userTotalInvestReward)).toFixed(2)} $</h6> */}
            {/* <h6>User invite rewards: {inviteRewards.toFixed(2)} $</h6> */}
            {/* <h6>Previous unclaimed rewards: {previousUnclaimedInvestReward.toFixed(2)} $</h6> */}
            {/* <h6>User All Received Rewards: {userClaimedRewards.toFixed(2)} $</h6> */}
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-blue-400">
            <p>Withdraw user rewards:</p>
            {/* <p className="text-[10px] max-w-full break-words ">{currentOwner}</p> */}
            <input
              className="w-full border bg-gray-700 border-blue-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] mt-2 placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
              type="text"
              value={infoAddressVal2}
              onChange={(e: any) => setInfoAddressVal2(e.target.value)}
            />
            <button
              onClick={withdrawUserRewardByOwner}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Withdraw
            </button>
          </div>
          <div className="flex flex-col  items-center p-4 rounded-2xl border border-red-400">
            <div className="grid">
            <p>All app balance:</p>
            <p>(Only in necessary condition)</p>
            </div>
            {/* <p>{contractBalance}</p>$ */}
            <p>{allContractBalance}</p>$
            {/* <p className="text-[10px] max-w-full break-words ">{currentOwner}</p> */}
            <input
              className="w-full border bg-gray-700 border-blue-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] mt-2 placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
              type="text"
              value={withdrawBalanceVal}
              onChange={(e: any) => setWithdrawBalanceVal(e.target.value)}
            />
            <button
              onClick={withdrawBalance}
              className="flex justify-center items-center self-stretch px-2 py-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-red-600 to-red-300 hover:bg-gradient-to-bl rounded-2xl transition-all"
            >
              Withdraw
            </button>
          </div>
        </div>
        <div className="mt-24">
          <div className="px-4 py-8 border border-blue-400 rounded-lg">
            <div className="flex justify-center items-center mb-8 flex-wrap">
              <input
                className="border bg-gray-700 border-gray-600 rounded-2xl w-full sm:w-auto p-[12px] pl-[20px] pr-[40px] placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
                type="text"
                value={blockedSearch}
                onChange={(e: any) => setBlockedSearch(e.target.value)}
              />
              <button
                onClick={() => blockUser(blockedSearch)}
                type="button"
                className="flex justify-center items-center self-stretch px-2 py-3 mt-4 w-full sm:mt-0 sm:w-auto sm:ml-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-2xl transition-all"
              >
                Block
              </button>
            </div>

            <Tabs value="active">
              <TabsHeader placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                {data.map(({ label, value }) => (
                  <Tab
                    key={value}
                    value={value}
                    placeholder=""
                    onPointerEnterCapture={() => {}}
                    onPointerLeaveCapture={() => {}}
                  >
                    {label}
                  </Tab>
                ))}
              </TabsHeader>
              <TabsBody placeholder="" onPointerEnterCapture={() => {}} onPointerLeaveCapture={() => {}}>
                {data.map(({ value, desc }) => (
                  <TabPanel key={value} value={value}>
                    {desc}
                  </TabPanel>
                ))}
              </TabsBody>
            </Tabs>
          </div>
          <div className="p-8 border border-blue-400 rounded-lg mt-24">
            <p className="m-8 text-[18px]">Blocked users</p>
            {blockedUsers.map((user: string, key: number) => (
              <div key={key}>
                <div className="flex flex-col md:flex-row md:justify-between md:items-center mb-8">
                  <p className="break-words">{user}</p>
                  <button
                    onClick={() => unblockUser(user)}
                    className="flex justify-center items-center px-2 py-3 mt-4 md:mt-0 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-2xl transition-all"
                  >
                    Unblock
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
        {loading ? <Loading loading={loading} setLoading={setLoading} /> : null}
        {alertModall ? (
          <AlertModal
            alertModal={alertModall}
            status={alertStatus}
            setAlertModal={setAlertModal}
            setStatus={setAlertStatus}
          />
        ) : null}
      </div>
    </>
  );
};

export default PanelV2;
