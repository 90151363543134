
const Production  = () => {
    return (
      <footer className="p-3 pt-0 min-h-screen flex justify-center items-center">
        {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-[15px] gap-4"> */}
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 px-[15px] gap-4">
          <div className="my-[1px] px-[1px]">
            <div>Production:</div>
            <p className="mt-[1px] text-gray-400">
            -Creating diverse businesses has always been an inseparable part of great ideas and companies.
 - KHF, in addition to accuracy in the field of Internet technology, also emphasizes on production.     -Investing in the energy sector is one of the economic strategies.
            </p>
          </div>
          
        </div>
      </footer>
    );
  };
  
  export default Production;
  