
const Financial = () => {
  return (
    <footer className="p-3 pt-0 min-h-screen flex justify-center items-center">
      {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-[15px] gap-4"> */}
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 px-[15px] gap-4">
        <div className="my-[1px] px-[1px]">
          <div>Financial (financial fund):</div>
          <p className="mt-[1px] text-gray-400">
          -You must have heard that when the name of financial markets comes up, the first thought goes to transactions, forex market, gold, etc.
          -Asset trading is an integral part of financial markets. But where money is directly concerned, there is also the concern of losing money.
          - Again, KHF has thought of a solution in this field that gives the best traders the initial funds for trading and has been able to solve this case as well.
          -In the capital supply section, funds in various amounts from low to millions of dollars are awarded to traders.
          </p>
        </div>
        
      </div>
    </footer>
  );
};

export default Financial;
