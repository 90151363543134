import React, { useState } from 'react';

export default function ForexLinksDropDown() {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="relative inline-block text-left">
      <button
        // href="#Connect"
        onClick={toggleDropdown}
        className="flex justify-center items-center py-3 px-6 mt-6 font-[400] md:px-[2.6rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-lg transition-all cursor-pointer"
      >
        {`Links`}
      </button>
      {isOpen && (
        // <div className="absolute right-0 mt-2 w-48 bg-white border border-gray-200 rounded shadow-lg">
        <div className="absolute right-0 mt-2 w-48 bg-gradient-to-br from-green-400 to-blue-400 hover:bg-gradient-to-bl border border-gray-400 rounded shadow-lg">
          <a href="https://www.litefinance.org/fa/?uid=888792395" target='_blank' className="block font-[400] px-4 py-2 border border-gray-500 text-gray-100 hover:bg-green-400">Broker</a>
          <a href="https://sarmayegozarebartar.com/?ref=8790" target='_blank' className="block font-[400] px-4 py-2 border border-gray-500 text-gray-100 hover:bg-green-400">Prop</a>
        </div>
      )}
    </div>
  );
}
