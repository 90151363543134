import { rejects } from "assert";
import { toast } from "react-toastify";
import { StakingDetails } from "../types/Stake";
import { toEther, toWei } from "../utils/utils";

const zeroAddress = '0x0000000000000000000000000000000000000000';

export const checkConnect = (account : string) => {
    if(!account){
      toast.error("Please connect your wallet.")
      throw 'Please connect your wallet.';
    }
  }

export const getUserReward = async (stakingContract:any, account:string) => {
  if(!stakingContract || !account) return;
  const reward = await stakingContract.pendingRewards(account);
  if(Number(toEther(reward)) > 0){
  return Number(toEther(reward));
}else{
  return 0;
}
}


export const checkIsStaker = async (stakingContract : any, account:string) => {
    const owner = await stakingContract.owner()
    const details = await stakingContract.stakers(account);
    if(details?.parent === '0x0000000000000000000000000000000000000000' && account !==owner){
        toast.error("You are not active, please active first.")
        return false;
      }
  }


  export const checkIsStakeAble = async (stakingContract : any, account:string) => {
    const owner = await stakingContract.owner()
    const details = await stakingContract.stakers(account);
    const rankAmount = await stakingContract.stakeAmounts(details?.rank);
    const previosRank = details?.rank == 0 ? 0 : details?.rank -1;
    const previosRankAmount = await stakingContract.stakeAmounts(previosRank);
    const maxRate5 = await stakingContract.maxRate5();
    const maxRate10 = await stakingContract.maxRate10();
    const currentTime = Math.floor(Date.now() / 1000);

    // console.log('rankAmount:',Number(rankAmount))
    // console.log('maxRate:',Number(maxRate))
    // console.log('rank:',details?.rank)
    // console.log('earned:',Number(details?.earn))
    // console.log('contractEndTime:',Number(details?.contractEndTime))

    if(details?.active === false && details?.rank ==1 && Number(previosRankAmount)*Number(maxRate10) <= Number(details?.earn)){
        console.log('stake reason: earn is more than the max earn per rank and user should stake again')
        return true;
      } else if(details?.active === false && details?.rank !==0 && details?.rank !==1 && details?.rank !==10 && Number(previosRankAmount)*Number(maxRate5) <= Number(details?.earn)){
        console.log('stake reason: earn is more than the max earn per rank and user should stake again')
        return true;
      } else if(details?.active === false && details?.rank ===10 && Number(rankAmount)*Number(maxRate5) <= Number(details?.earn)){
        console.log('stake reason: user is stake again in rank 10')
        return true;
      } else if(details?.active === false && details?.parent == zeroAddress && details?.rank==0){
      console.log('stake reason: stake for first time')
      return true;
    }else if(details?.active === false && Number(details?.contractEndTime) < currentTime){
      console.log('stake reason: restake because contract time has ended')
      return true;
    }else{
      toast.error("You can not stake again until your rank be increased")
      return false;
    }
  }

  
  export const checkIsShouldStake = async (stakingContract : any, account:string) => {
    const owner = await stakingContract.owner()
    const details = await stakingContract.stakers(account);
    const rankAmount = await stakingContract.stakeAmounts(details?.rank);
    const previosRank = details?.rank == 0 ? 0 : details?.rank -1;
    const previosRankAmount = await stakingContract.stakeAmounts(previosRank);
    const maxRate5 = await stakingContract.maxRate5();
    const maxRate10 = await stakingContract.maxRate10();
    const currentTime = Math.floor(Date.now() / 1000);

    // console.log('rankAmount:',Number(rankAmount))
    // console.log('maxRate:',Number(maxRate))
    // console.log('rank:',details?.rank)
    // console.log('earned:',Number(details?.earn))
    // console.log('contractEndTime:',Number(details?.contractEndTime))
    /*
    if(details?.active === false && details?.rank !==0 && details?.rank !==10 && previosRankAmount*Number(maxRate) <= Number(details?.earn)){
        return true;
      }else if(details?.active === false && details?.rank ===10 && rankAmount*Number(maxRate) <= Number(details?.earn)){
        return true;
      } else if(details?.active === false && details?.parent == zeroAddress && details?.rank==0){
      return true;
    }else if(details?.active === false && Number(details?.contractEndTime) < currentTime){
      return true;
    }else{
      return false;
    }
    */

    if(details?.active === false && details?.rank ==1 && Number(previosRankAmount)*Number(maxRate10) <= Number(details?.earn)){
      return true;
    } else if(details?.active === false && details?.rank !==0 && details?.rank !==1 && details?.rank !==10 && Number(previosRankAmount)*Number(maxRate5) <= Number(details?.earn)){
      return true;
    } else if(details?.active === false && details?.rank ===10 && Number(rankAmount)*Number(maxRate5) <= Number(details?.earn)){
      return true;
    } else if(details?.active === false && details?.parent == zeroAddress && details?.rank==0){
    return true;
  }else if(details?.active === false && Number(details?.contractEndTime) < currentTime){
    return true;
  }else{
    return false;
  }
  }

  export const checkIsActiveToReward = async (stakingContract : any, account:string) => {
    const owner = await stakingContract.owner()
    const details = await stakingContract.stakers(account);
    if(details?.active === false && account !==owner){
        toast.error("You can not withdraw until to stake in your new rank")
        return false;
      }
  }

  export const checkIsNotBlocked = async (stakingContract : any, account:string) => {
    const owner = await stakingContract.owner()
    const details = await stakingContract.stakers(account);
    if(details?.freezed === true && account !==owner){
        toast.error("You can not withdraw because you are blocked")
        return false;
      }
  }

  export const checkAppIsActive = async (stakingContract : any) => {
    const status = await applicationStatus(stakingContract);
    if(status === "STOPPED"){
        toast.error("The app is stopped, please wait for the later start time.")
        return false;
      }
  }

  

  export const checkHasEnoughRewardToWithdraw = async (desireAmount:any, stakingContract : any, account:string) => {
    const reward = await getUserReward(stakingContract, account);
    if(Number(reward) < desireAmount){
        toast.error("You dont have enough reward to withdraw")
        return false;
      }
  }

  export const checkHasEnoughContractTime = async (stakingContract : any, account:string) => {
    const owner = await stakingContract.owner()
    const details = await stakingContract.stakers(account);
    const currentTime = Math.floor(Date.now() / 1000);

    if(Number(details?.contractEndTime) < currentTime && account !== owner){
        toast.error("You can not withdraw because your contract is ended and you need to stake again")
        return false;
      }
  }

export const getStakeAmount = async (stakingContract : any, account:string) => {
  if(!stakingContract || !account) return
  const details = await stakingContract.stakers(account);
  const stakeAmount = await stakingContract.stakeAmounts(details.rank);
  const satkeCurr = await stakingContract.inputToken();
  let fee;
  let stakeCurrency;
  let payAmount;
  if(details.rank === 0){
    fee = 10;
  }else{
    fee=10
  }

  const totalAmount = Number(toEther(stakeAmount))*(100+fee)/100;

  if(satkeCurr === 0){
    stakeCurrency = 'USDT'
    payAmount = totalAmount;
  }
  if(satkeCurr === 1){
    stakeCurrency = 'BNB'
    const bnbAmount = await stakingContract.getAmountEthOut(toWei(totalAmount.toString()));
    payAmount = toEther(bnbAmount);
  }
  
  return {
    rank : details.rank,
    stakeAmount: Number(toEther(stakeAmount)),
    fee,
    totalAmount,
    stakeCurrency,
    payAmount
  };
}


export const getStakeAmountV2 = async (stakingContract : any, account:string) => {
  if(!stakingContract || !account) return
  const details = await stakingContract.stakers(account);
  const stakeAmount = await stakingContract.stakeAmounts(details.rank);
  const satkeCurr = await stakingContract.inputToken();
  let fee;
  let stakeCurrency;
  let payAmount;
  if(details.rank === 0){
    fee = 5;
  }else{
    fee=5
  }

  const totalAmount = Number(toEther(stakeAmount))*(100+fee)/100;

  if(satkeCurr === 0){
    stakeCurrency = 'USDT'
    payAmount = totalAmount;
  }
  if(satkeCurr === 1){
    stakeCurrency = 'BNB'
    const bnbAmount = await stakingContract.getAmountEthOut(toWei(totalAmount.toString()));
    payAmount = toEther(bnbAmount);
  }
  
  return {
    rank : details.rank,
    stakeAmount: Number(toEther(stakeAmount)),
    fee,
    totalAmount,
    stakeCurrency,
    payAmount
  };
}

export const hasEnoughBalance = async (stakingContract:any, usdtContract:any, signer:any, account:string, totalAmount:number) => {
  if(!usdtContract || !account) return
  const inputCurrency = await getInputCurrency(stakingContract);
  if(inputCurrency === "USDT"){
  const balance = await usdtContract.balanceOf(account);
  if(Number(toEther(balance)) < totalAmount){
    toast.error(`Your balance is not enough to stake! (require: ${totalAmount} $)`)
    return false;
  }else{
    return true;
  }
}
if(inputCurrency === "BNB"){
  const balance = await signer.getBalance(account);
  const bnbAmount = await stakingContract.getAmountEthOut(toWei(totalAmount.toString() as string));
  if(Number(balance) < Number(bnbAmount)){
    toast.error(`Your balance is not enough to stake! (require: ${toEther(bnbAmount)} BNB)`)
    return false;
  }else{
    return true;
  }
}
}


export const isStaker = async (stakingContract:any, account:string) => {
  const owner = await stakingContract.owner()
  const details = await stakingContract.stakers(account);
  if(details?.parent === '0x0000000000000000000000000000000000000000' && account !==owner){
    return false;
  }else{
    return true;
  }
}

export const getRefer = async (stakingContract:any, account:string, url: string) => {
  const owner = await stakingContract.owner()
  var lnkStr = window.location.href;   //document.URL;   
  let index = url.indexOf('?ref=');
  let referAdd = '';
  if (index >= 0) {
      referAdd = url.substring(index + 5, index + 5 + 42);
      console.log("referAdd:", referAdd)
      const referIsStaker = await isStaker(stakingContract, referAdd);
      if(referAdd === account){
        toast.error(`Your cant put your address as refer!`)
        return {
          address: owner,
          isCorrect: false
        }
      }
      if(referIsStaker === false){
        toast.error(`Your refer address is not staker!`)
        return {
          address: owner,
          isCorrect: false
        }
      }
      
      if(referIsStaker === true){
        return {
          address: referAdd,
          isCorrect: true
        }
      }
  }
  else {
    return {
      address: owner,
      isCorrect: true
    }
  }
}


export const getInputCurrency = async (stakingContract : any) => {
  if(!stakingContract) return;
  const inputC = await stakingContract.inputToken();
  if(inputC === 0){
    return "USDT";
  }
  if(inputC === 1){
    return "BNB";
  }
}


export const getOutputCurrency = async (stakingContract : any) => {
  if(!stakingContract) return;
  const outputC = await stakingContract.outputToken();
  if(outputC === 0){
    return "USDT";
  }
  if(outputC === 1){
    return "BNB";
  }
}


export const applicationStatus = async (stakingContract : any) => {
  if(!stakingContract) return;
  const isStopped = await stakingContract.paused();
  if(isStopped === true){
    return "STOPPED";
  }
  if(isStopped === false){
    return "ACTIVE";
  }
}

export const getContractFreeBalance = async (stakingContract : any, usdtContract: any) => {
  if(!stakingContract || !usdtContract) return;
  const contractBalance = await usdtContract.balanceOf(stakingContract.address);
  const allPendingRewards = await stakingContract.totalPending();

  if(Number(toEther(contractBalance))>0 && Number(toEther(allPendingRewards))>0){
  const freeBalance = Number(toEther(contractBalance)) - Number(toEther(allPendingRewards));
  
  return {
    contractBalance: Number(toEther(contractBalance)),
    allPendingRewards: Number(toEther(allPendingRewards)),
    freeBalance: freeBalance,
  }
  
  }else{
    return {
      contractBalance:0,
      allPendingRewards:0,
      freeBalance:0
    };
  }
}

export const getBlockedUsers = async (stakingContract:any):Promise<string[]|undefined> => {
  if(!stakingContract) return;
  const allUsers = await stakingContract.totalUsers();
  let blockedUsers:string[]=[];
  for(const user of allUsers){
    const detail = await stakingContract.stakers(user);
    if(detail.freezed === true){
      blockedUsers.push(user);
    }
  }
  return blockedUsers;
}


export const getActiveUsers = async (stakingContract:any):Promise<string[]|undefined> => {
  if(!stakingContract) return;
  const allUsers = await stakingContract.totalUsers();
  console.log(allUsers)
  let activeUsers:string[]=[];
  for(const user of allUsers){
    const detail = await stakingContract.stakers(user);
    if(detail.freezed === false && detail.active == true){
      activeUsers.push(user);
    }
  }
  return activeUsers;
}

export const getDeActiveUsers = async (stakingContract:any):Promise<string[]|undefined> => {
  if(!stakingContract) return;
  const allUsers = await stakingContract.totalUsers();
  console.log(allUsers)
  let activeUsers:string[]=[];
  for(const user of allUsers){
    const detail = await stakingContract.stakers(user);
    if(detail.freezed === false && detail.active == false){
      activeUsers.push(user);
    }
  }
  return activeUsers;
}

export const checkOwner = async (stakingContract:any, account:string, setLoading:any) => {
  if(!stakingContract || !account) return;
  const currentOwner = await stakingContract.owner();
  if(currentOwner !== account){
    toast.error(`You are not owner!`)
    setLoading(false);
    return false;
  }
}

