
const Distribution  = () => {
    return (
      <footer className="p-3 pt-0 min-h-screen flex justify-center items-center">
        {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-[15px] gap-4"> */}
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 px-[15px] gap-4">
          <div className="my-[1px] px-[1px]">
            <div>Distribution:</div>
            <p className="mt-[1px] text-gray-400">
            -Considering that the users should be at the highest levels, another service of KHF is to create a bridge between users and the desired stores to serve users with a variety of payments that allow users to live without worries.
            </p>
          </div>
          
        </div>
      </footer>
    );
  };
  
  export default Distribution;
  