import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./dapp/dappSlice";

export const store = configureStore({
  reducer: {
    counter : rootReducer
  },
  middleware: getDefaultMiddleware({
    serializableCheck: false,
  }),
});


