
const More  = () => {
    return (
      <footer className="p-3 pt-0 min-h-screen flex justify-center items-center">
        {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-[15px] gap-4"> */}
        <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 px-[15px] gap-4">
          <div className="my-[1px] px-[1px]">
            <div>More:</div>
            <p className="mt-[1px] text-gray-400">
            -All the best ideas and great companies always provide better services by moving the world forward.
            -KHF is not exempt from this point of view.
            -Creating investment sectors on agricultural products and other products. 
            - Investing and creating partnerships with large road and construction projects and companies.
            - All kinds of facilities for users and creation of other job opportunities are examples of services. 
            -KHF  tries to find job opportunities, better services and solve problems that all users around the world are facing. 
            Therefore, several projects with different focuses are under consideration to provide better services to users.
            </p>
          </div>
          
        </div>
      </footer>
    );
  };
  
  export default More;
  