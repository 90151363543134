import { useSelector } from "react-redux";
import Connect from "./Connect";
import CopyRight from "./CopyRight";
import Details from "./Details";
import DetailsV2 from "./DetailsV2";
import Home from "./Home";
import Invite from "./Invite";
import Stake from "./Stake";
import StakeV2 from "./StakeV2";

const HomePage = () => {

  const version = useSelector((state: any) => state.counter.version);

  return (
  <>
  <Home/>
  <Connect/>
  {version==1 ? <Stake/> : <StakeV2/>}
  {version==1 ? <Details/> : <DetailsV2/>}
  <Invite/>
  <CopyRight />
  </>
  )
  
};

export default HomePage;
