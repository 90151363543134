import { useState, useEffect, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { Sling as Hamburger } from "hamburger-react";
import { Switch } from "@headlessui/react";
import { motion } from "framer-motion";
import { Link, useLocation } from "react-router-dom";
import { BsFillMoonFill, BsNewspaper } from "react-icons/bs";
import { FaHome } from "react-icons/fa";
import { HashLink } from "react-router-hash-link";
import { IoMdWallet } from "react-icons/io";
import { GiWantedReward, GiStakesFence } from "react-icons/gi";
import Slider from "./Slider";
import logo from "../images/logo.png";
import {
  MdOutlineInsertInvitation,
  MdAdminPanelSettings,
} from "react-icons/md";
import { BsFillFileEarmarkPersonFill } from "react-icons/bs";
import about from "../images/about.png";
import { BiSun } from "react-icons/bi";
import HeaderSlider from "./HeaderSlider";
import { useSelector } from "react-redux";
import VersionDropdown from "./VersionDropdown";

const Header = (props: any) => {
  // const [theme, setTheme] = useState<boolean>(
  //   localStorage.getItem("theme") === "dark" ? true : false
  // );

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [isOwner, setIsOwner] = useState<boolean>(false);
  const [browserWidth, setBrowserWidth] = useState<number>(0);
  const [scrollValue, setScrollValue] = useState<number>(0);

  const ref = useRef<HTMLDivElement>(null);

  const account = useSelector((state: any) => state.counter.account);
  const version = useSelector((state: any) => state.counter.version);
  const tokenContract = useSelector(
    (state: any) => state.counter.tokenContract
  );
  const stakingContract = useSelector(
    (state: any) => state.counter.stakingContract
  );
  const stakingV2Contract = useSelector(
    (state: any) => state.counter.stakingV2Contract
  );

  const checkOwner = async () => {
    if (stakingContract && account && version==1) {
      const owner = await stakingContract.owner();
      if (owner === account) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }
    }
  };


  const checkOwnerV2 = async () => {
    console.log(stakingV2Contract)
    if (stakingV2Contract && account && version==2) {
      const owner = await stakingV2Contract.owner();
      if (owner === account) {
        setIsOwner(true);
      } else {
        setIsOwner(false);
      }
    }
  };

  useEffect(() => {
    checkOwner();
  }, [account, stakingContract, version]);

  useEffect(() => {
    checkOwnerV2();
  }, [account, stakingV2Contract, version]);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsMenuOpen(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  const menuVariants = {
    opened: {
      top: `${scrollValue <= 60 ? "124px" : "64px"}`,
      transition: {
        when: "beforeChildren",
        staggerChildren: 0.3,
      },
    },
    closed: {
      top: "-100vh",
    },
  };

  const linkVariants = {
    opened: {
      opacity: 1,
      x: 5,
    },
    closed: {
      opacity: 0,
      x: 0,
    },
  };

  // useEffect(() => {
  //   if (theme) {
  //     localStorage.setItem("theme", "dark");
  //     document.documentElement.classList.add("dark");
  //   } else {
  //     localStorage.setItem("theme", "light");
  //     document.documentElement.classList.remove("dark");
  //   }
  // }, [theme]);

  // const [searchParams] = useSearchParams();

  useEffect(() => {
    if (browserWidth >= 992) {
      setIsMenuOpen(false);
      document.body.style.overflow = "visible";
    }
  }, [browserWidth]);

  useEffect(() => {
    const onScroll = (e: any) => {
      setScrollValue(e.target.documentElement.scrollTop);
    };

    const handleResize = () => {
      setBrowserWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "unset";
    }

    return () => {
      document.documentElement.style.overflow = "unset";
    };
  }, [isMenuOpen]);

  return (
    <>
      <HeaderSlider />
      <header className="sticky bg-black z-10 top-0" ref={ref}>
          <nav className="w-full h-16 flex items-center justify-between lg:justify-start px-3">
          <div className="logo cursor-pointer scroll-auto">
            <HashLink
              onClick={() => setIsMenuOpen(false)}
              to={
                window.location.search
                  ? window.location.search + "#Home"
                  : "/#Home"
              }
            >
              <img className="w-[70px]" src={logo} />
            </HashLink>
          </div>
          {/* <VersionDropdown /> */}
          
          <div className="hidden lg:flex ml-auto scroll-auto">
            <ul className="flex items-center text-gray-400 gap-3 font-semibold text-lg scroll-auto">
              <li className="cursor-pointer text-inherit hover:text-white  transition-all ease-linear duration-200">
                <HashLink
                  className="block w-full h-full px-4 py-[20px]"
                  to={
                    window.location.search
                      ? window.location.search + "#Home"
                      : "/#Home"
                  }
                >
                  Home
                </HashLink>
              </li>

              <li className="cursor-pointer hover:text-white transition-all ease-linear duration-200">
                <HashLink
                  className="block w-full h-full px-4 py-[20px]"
                  to={
                    window.location.search
                      ? window.location.search + "#Connect"
                      : "/#Connect"
                  }
                >
                  Connect
                </HashLink>
              </li>

              <li className="cursor-pointer hover:text-white transition-all ease-linear duration-200">
                <HashLink
                  className="block w-full h-full px-4 py-[20px]"
                  to={
                    window.location.search
                      ? window.location.search + "#Reward"
                      : "/#Reward"
                  }
                >
                  Reward
                </HashLink>
              </li>

              <li className="cursor-pointer hover:text-white transition-all ease-linear duration-200">
                <HashLink
                  className="block w-full h-full px-4 py-[20px]"
                  to={
                    window.location.search
                      ? window.location.search + "#Stake"
                      : "/#Stake"
                  }
                >
                  Active
                </HashLink>
              </li>
              <li className="cursor-pointer hover:text-white transition-all ease-linear duration-200">
                <HashLink
                  className="block w-full h-full px-4 py-[20px]"
                  to={
                    window.location.search
                      ? window.location.search + "#Invite"
                      : "/#Invite"
                  }
                >
                  Invite
                </HashLink>
              </li>
              <li className="cursor-pointer hover:text-white transition-all ease-linear duration-200">
                <Link
                  className="block w-full h-full px-4 py-[20px]"
                  to="/about-us"
                >
                  About us
                </Link>
              </li>
                {isOwner && (
              <li className="cursor-pointer hover:text-white transition-all ease-linear duration-200">
                <a
                  className="block w-full h-full px-4 py-[20px]"
                  href={`https://bscscan.com/address/${version ==1 ? stakingContract?.address : stakingV2Contract?.address}`}
                  target="_blank"
                >
                  Contract
                </a>
              </li>
              )}

              {isOwner ? (
                <li className="cursor-pointer hover:text-white transition-all ease-linear duration-200">
                  <Link
                    to="/panel"
                    className="block w-full h-full px-4 py-[20px]"
                  >
                    Panel
                  </Link>
                </li>
              ):null}
            </ul>
          <div className="flex items-center">
            <VersionDropdown />
          </div>
          </div>

          <div className="menuIcon lg:hidden rounded-md mr-0">
            <Hamburger
              toggled={isMenuOpen}
              onToggle={() => setIsMenuOpen(!isMenuOpen)}
              duration={0.4}
              rounded
              size={30}
            />
          </div>
        </nav>
        {/* Responsive navbar */}
        <motion.ul
          initial={false}
          variants={menuVariants}
          animate={isMenuOpen ? "opened" : "closed"}
          className="h-3/5 fixed z-0 overflow-scroll p-2 sm:p-5 text-md font-semibold w-full text-gray-100 bg-black items-center shadow-2xl"
        >
          <motion.li
            onClick={() => setIsMenuOpen(false)}
            variants={linkVariants}
            className="cursor-pointer my-1 sm:my-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
          >
            <HashLink
              className="flex items-center p-2"
              to={
                window.location.search
                  ? window.location.search + "#Home"
                  : "/#Home"
              }
            >
              <FaHome size={20} />
              <span className="ml-4">Home</span>
            </HashLink>
          </motion.li>
          <motion.li
            onClick={() => setIsMenuOpen(false)}
            variants={linkVariants}
            className="cursor-pointer my-1 sm:my-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
          >
            <HashLink
              className="flex items-center p-2"
              to={
                window.location.search
                  ? window.location.search + "#Connect"
                  : "/#Connect"
              }
            >
              <IoMdWallet size={20} />
              <span className="ml-4">Connect</span>
            </HashLink>
          </motion.li>
          <motion.li
            onClick={() => setIsMenuOpen(false)}
            variants={linkVariants}
            className="cursor-pointer my-1 sm:my-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
          >
            <HashLink
              className="flex items-center p-2"
              to={
                window.location.search
                  ? window.location.search + "#Reward"
                  : "/#Reward"
              }
            >
              <GiWantedReward size={20} />
              <span className="ml-4">Reward</span>
            </HashLink>
          </motion.li>
          <motion.li
            onClick={() => setIsMenuOpen(false)}
            variants={linkVariants}
            className="cursor-pointer my-1 sm:my-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
          >
            <HashLink
              className="flex items-center p-2"
              to={
                window.location.search
                  ? window.location.search + "#Stake"
                  : "/#Stake"
              }
            >
              <GiStakesFence size={20} />
              <span className="ml-4"> Active</span>
            </HashLink>
          </motion.li>
          {!isOwner && (
          <motion.li
            onClick={() => setIsMenuOpen(false)}
            variants={linkVariants}
            className="cursor-pointer my-1 sm:my-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
          >
            <HashLink
              className="flex items-center p-2"
              to={
                window.location.search
                  ? window.location.search + "#Invite"
                  : "/#Invite"
              }
            >
              <MdOutlineInsertInvitation size={20} />
              <span className="ml-4">Invite</span>
            </HashLink>
          </motion.li>
          )}
          <motion.li
            className="cursor-pointer my-1 sm:my-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
            variants={linkVariants}
          >
            <Link
              to="/about-us"
              className="flex items-center p-2"
              onClick={() => setIsMenuOpen(false)}
            >
              <BsFillFileEarmarkPersonFill size={20} />
              <span className="ml-4">About us</span>
            </Link>
          </motion.li>
          
          {isOwner && (
          <motion.li
            className="cursor-pointer my-1 sm:my-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
            variants={linkVariants}
          >
            <a
              href={`https://bscscan.com/address/${version ==1 ? stakingContract?.address : stakingV2Contract?.address}`}
              className="flex items-center p-2"
              onClick={() => setIsMenuOpen(false)}
              target="_blank"
            >
              <BsNewspaper size={20} />
              <span className="ml-4">Contract</span>
            </a>
          </motion.li>
          )}
          {isOwner ? (
            <motion.li
              className="cursor-pointer my-1 sm:my-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
              variants={linkVariants}
            >
              <Link
                to="/panel"
                className="flex items-center p-2"
                onClick={() => setIsMenuOpen(false)}
              >
                <MdAdminPanelSettings size={20} />
                <span className="ml-4">Panel</span>
              </Link>
            </motion.li>
          ): null}
          <motion.li
              className="cursor-pointer my-4 sm:my-4 text-gray-400 hover:text-white transition-colors ease-linear duration-200"
              variants={linkVariants}
            >
          <div className="flex ml-2 items-start">
            <VersionDropdown />
          </div>
          </motion.li>
          
        </motion.ul>
      </header>
    </>
  );
};

export default Header;