import React, { useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import binance from "../images/binance.png";
import bitcoin from "../images/bitcoin.png";
import ethereum from "../images/ethereum.png";
import tron from "../images/tron.png";
import { fetchJson } from "ethers/lib/utils";
import axios from "axios";
const url =
  "https://api.coingecko.com/api/v3/simple/price?ids=binancecoin&vs_currencies=usd";

const useStyles = makeStyles({
  // "@keyframes moveSlideshow": {
  //   "100%": {
  //     transform: "translateX(-66%)",
  //   },
  "@keyframes scrolling": {
    "0%": {
      transform: "translateX(70rem)",
    },
    "100%": {
      transform: "translateX(-95rem)",
    },
  
  },
  container: {
    color: "white",
    textAlign: "center",
    justifyContent: "center",
    display: "flex",
  },
  content: {
    width: "80rem",
    height: "40px",
    marginTop: "20px",
    backgroundColor: "transparent",
    color: "black",
    overflow: 'hidden',
    position: "relative",
  },
  scrollContent: {
    animation: "$scrolling 15s linear infinite",
    listStyle: "none",
    height: "100%",
    display: "flex",
  },
  li: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexShrink: 0,
    width: "10rem",
    maxHeight: "100%",
    fontSize: "10px",
    whiteSpace: "nowrap",
  },
});

export default function HeaderSlider() {
  const [priceObj, setPriceObj] = useState<any>();
  const [btcPrice, setBtcPrice] = useState<any>(0);
  const [ethPrice, setEthPrice] = useState<any>();
  const [bnbPrice, setBnbPrice] = useState<any>();
  const [adaPrice, setAdaPrice] = useState<any>();
  const [solPrice, setSolPrice] = useState<any>();
  const [dotPrice, setDotPrice] = useState<any>();
  const [linkPrice, setLinkPrice] = useState<any>();
  const [cakePrice, setCakePrice] = useState<any>();
  const [aavePrice, setAavePrice] = useState<any>();
  const classes = useStyles();

  const receiveFetch = async (currencyName:any) => {
    try {
     
    // const url = `https://api.coingecko.com/api/v3/simple/price?ids=${currencyName}&vs_currencies=usd`
    // let response:any = await axios.get(url);
    const response:any = await axios.get(`https://api.coingecko.com/api/v3/simple/price?ids=${currencyName}&vs_currencies=usd`, {
      headers: {
        "Control-Allow-Origin": "*",
        // 'X-CMC_PRO_API_KEY': 'b54bcf4d-1bca-4e8e-9a24-22ff2c3d462c',
      },
    });
    console.log(response.data);
    // return response?.currencyName?.usd;
    return response.data;
     
    } catch (error) {
        console.log("coingecko error: " + error)
    }
  }

  const getPrice = async() => {
    const obj = await receiveFetch(['bitcoin', 'binancecoin', 'ethereum', 'cardano', 'solana', 'polkadot', 'chainlink', 'cake','aave']);
    // return;
    // const btc = await receiveFetch('bitcoin');
    setBtcPrice(obj?.bitcoin?.usd);

    // const bnb = await receiveFetch('binancecoin');
    setBnbPrice(obj?.binancecoin?.usd);

    // const eth = await receiveFetch('ethereum');
    setEthPrice(obj?.ethereum?.usd);

    // const ada = await receiveFetch('cardano');
    setAdaPrice(obj?.cardano?.usd);

    // const sol = await receiveFetch('solana');
    setSolPrice(obj?.solana?.usd);

    // const dot = await receiveFetch('polkadot');
    setDotPrice(obj?.polkadot?.usd);

    // const link = await receiveFetch('chainlink');
    setLinkPrice(obj?.chainlink?.usd);

    // const cake = await receiveFetch('cake');
    setCakePrice(obj?.cake?.usd);

    // const aave = await receiveFetch('aave');
    setAavePrice(obj?.aave?.usd);
  }

  useEffect(() => {
    getPrice();
  },[]);
  return (
    <div
      className={classes.container}
      style={{
        // backgroundColor: uiStatus === 'dark' ? '#001328' : "#edb879",
        backgroundColor: "#000000",
      }}
    >
      <div className={classes.content}>
        <ul className={classes.scrollContent}>
          <li className={classes.li}>
            <figure className="flex justify-center items-center">
             {/* <h1>Minimum Register with: 30 $</h1> */}
              <h2 className="text-white mx-4 ml-2 text-base">Minimum Register: 30 $</h2>
            </figure>
          </li>
          <li className={classes.li}>
            <figure className="flex justify-center items-center">
              {/* <MetaMaskIcon style={{
                                width: '2em', fontSize: 44,
                            }} /> */}
              <img
                src={binance}
                width={33}
                height={39}
                alt="trustwallet"
              />
              <figcaption className="text-white ml-2 text-base">: {bnbPrice} $</figcaption>
            </figure>
          </li>
          <li className={classes.li}>
            <figure className="flex justify-center items-center">
              {/* <WalletConnectIcon style={{
                                width: '2em', fontSize: 44
                            }} /> */}
              <img
                src={bitcoin}
                width={33}
                height={39}
                alt="metamask"
              />
              <figcaption className="text-white ml-2 text-base">: {btcPrice} $</figcaption>
            </figure>
          </li>
          <li className={classes.li}>
            <figure className="flex justify-center items-center">
              {/* <TrustWalletIcon style={{
                                width: '2em', fontSize: 44
                            }} /> */}
              <img
                src={ethereum}
                width={33}
                height={39}
                alt="coinbase wallet"
              />
              <figcaption className="text-white ml-2 text-base">: {ethPrice} $</figcaption>
            </figure>
          </li>
          <li className={classes.li}>
            <figure className="flex justify-center items-center">
              {/* <BinanceChainIcon style={{
                                width: '2em', fontSize: 44
                            }} /> */}
              <img
                src='https://s2.coinmarketcap.com/static/img/coins/64x64/2010.png'
                width={33}
                height={39}
                alt="cardano"
              />
              <figcaption className="text-white ml-2 text-base">: {adaPrice} $</figcaption>
            </figure>
          </li>
          <li className={classes.li}>
            <figure className="flex justify-center items-center">
              {/* <BinanceChainIcon style={{
                                width: '2em', fontSize: 44
                            }} /> */}
              <img
                src='https://s2.coinmarketcap.com/static/img/coins/64x64/5426.png'
                width={33}
                height={39}
                alt="solana"
              />
              <figcaption className="text-white ml-2 text-base">: {solPrice} $</figcaption>
            </figure>
          </li>
          <li className={classes.li}>
            <figure className="flex justify-center items-center">
              {/* <BinanceChainIcon style={{
                                width: '2em', fontSize: 44
                            }} /> */}
              <img
                src='https://s2.coinmarketcap.com/static/img/coins/64x64/6636.png'
                width={33}
                height={39}
                alt="polcadot"
              />
              <figcaption className="text-white ml-2 text-base">: {dotPrice} $</figcaption>
            </figure>
          </li>
          <li className={classes.li}>
            <figure className="flex justify-center items-center">
              {/* <BinanceChainIcon style={{
                                width: '2em', fontSize: 44
                            }} /> */}
              <img
                src='https://s2.coinmarketcap.com/static/img/coins/64x64/1975.png'
                width={33}
                height={39}
                alt="chainlink"
              />
              <figcaption className="text-white ml-2 text-base">: {linkPrice} $</figcaption>
            </figure>
          </li>
          <li className={classes.li}>
            <figure className="flex justify-center items-center">
              {/* <BinanceChainIcon style={{
                                width: '2em', fontSize: 44
                            }} /> */}
              <img
                src='https://s2.coinmarketcap.com/static/img/coins/64x64/7278.png'
                width={33}
                height={39}
                alt="aave"
              />
              <figcaption className="text-white ml-2 text-base">: {aavePrice} $</figcaption>
            </figure>
          </li>
          <li className={classes.li}>
            <figure className="flex justify-center items-center">
              {/* <BinanceChainIcon style={{
                                width: '2em', fontSize: 44
                            }} /> */}
              <img
                src='https://s2.coinmarketcap.com/static/img/coins/64x64/7186.png'
                width={33}
                height={39}
                alt="cake"
              />
              <figcaption className="text-white ml-2 text-base">: {cakePrice} $</figcaption>
            </figure>
          </li>
        </ul>
      </div>
    </div>
  );
}
