import React from 'react'
import { makeStyles } from '@mui/styles';
// import { selectedColor } from '../../app/uiSlice';
// import { useAppSelector } from '../../app/hooks';
// import MetaMaskIcon from '../images/icons/metaMaskIcon'
// import WalletConnectIcon from '../images/icons/walletConnectIcon';
// import TrustWalletIcon from '../images/icons/trustWalletIcon';
// import BinanceChainIcon from '../images/icons/binanceChainIcon';

const useStyles = makeStyles({
    "@keyframes scrolling": {
        "0%": {
            transform: "translateX(0)",
        },
        "100%": {
            transform: 'translateX(-20rem)',
        },
    },
    container: {
        color: 'white',
        textAlign: 'center',
        justifyContent: "center",
        display: "flex"
    },
    content: {
        width: '40rem',
        height: '110px',
        backgroundColor: 'transparent',
        color: 'black',
        overflow: 'hidden',
        position: 'relative',
    },
    scrollContent: {
        animation: '$scrolling 12s linear infinite',
        listStyle: 'none',
        height: '100%',
        display: 'flex',
    },
    li: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexShrink: 0,
        width: '10rem',
        maxHeight: '100%',
        fontSize: '10px',
        whiteSpace: 'nowrap'
    }
});

export default function Slider() {
    const classes = useStyles();
    // const uiStatus = useAppSelector(selectedColor);

    return (
        <div className={classes.container} style={{
            // backgroundColor: uiStatus === 'dark' ? '#001328' : "#edb879",
            backgroundColor: '#000000',
        }}>
            <div className={classes.content}>
                <ul className={classes.scrollContent}>
                    <li className={classes.li}>
                        <figure>
                            {/* <MetaMaskIcon style={{
                                width: '2em', fontSize: 44,
                            }} /> */}
                            <img
                                src='https://trustwallet.com/assets/images/media/assets/TWT.png'
                                width={33}
                                height={39}
                                alt="trustwallet"
                            />
                        </figure>
                    </li>
                    <li className={classes.li}>
                        <figure>
                            {/* <WalletConnectIcon style={{
                                width: '2em', fontSize: 44
                            }} /> */}
                            <img
                                src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/640px-MetaMask_Fox.svg.png'
                                width={33}
                                height={39}
                                alt="metamask"
                            />
                        </figure>
                    </li>
                    <li className={classes.li}>
                        <figure>
                            {/* <TrustWalletIcon style={{
                                width: '2em', fontSize: 44
                            }} /> */}
                            <img
                                src='https://avatars.githubusercontent.com/u/18060234?s=280&v=4'
                                width={33}
                                height={39}
                                alt="coinbase wallet"
                            />
                        </figure>
                    </li>
                    <li className={classes.li}>
                        <figure>
                            {/* <BinanceChainIcon style={{
                                width: '2em', fontSize: 44
                            }} /> */}
                            <img
                                src='https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/i/bde3849f-8b50-46e6-92fa-06b89b73054a/ddq1ks5-3b0f488c-3cda-42f6-81fc-a72579c52ba1.png'
                                width={33}
                                height={39}
                                alt="ledger"
                            />
                        </figure>
                    </li>
                    <li className={classes.li}>
                        <figure>
                            <img
                                src='data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADQAAAA0CAYAAADFeBvrAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAnBJREFUeNrsmj9IHEEUh2fX3Gn8g2I0xBRnTsQmjb2lYiUSglVIlwS0sJAYCIiNtYqVYGVEGxubxMIqKmgiiIVNiI1YSLpE8Zp43E5+LzsD63F3OyN7d3vDPPg4786ZnW/29s3szjicc5YXPeAlGAK9oF587ojXj2CDFY4U2AEtgLPyhAcuwQHYAt/ufEtCAd6Cn7x0jOeVCZIGGV65uAKLoF22wQ24zYAV0KfQQ8WCzkqWVS5awRRYAx30gRR6A+YC72stRsA8XR4k0C3OTq3KyHhFYiQxBtKs9iMB3pHQMDMnBlyRmk2JJhJKGiTEXWZYuPfIbm7chR5olqmLsxDJvKeLSWHu5Qj24y60Ydo1xKyQFbJCViiY5Z6K22yVtE3xB1zFWWgT9IOcghCd0VmwFGehNtCsUaYh7tdQTrOMZ5OCFbJCVsgKWaEIhZyI63Sq6UNCCc1CiRgLeQ7n/AXzn9x7Cg2lDjgCp0X+pxGMiqlUpWcU/zvSKbDgZZOCFbJCVsisZwrPwEPFNEsd8KvEM4WEqM/V6NBS9dFST0q0UyUdu5S2d/HHc41O+AA+FfmODr6neUs/zfxV7EJBi3FfwCPVgZXMO8XAGsUzBVqZeCwGWNVIhvyCnjB/+V75lOvuKwjbp3AbcX067fPKMTmtepbLVrkNUc69HBK6MOgEZUno0CChHyT0mfnbtUyIdRI6A8tVvGONKil9B6tyRF9gtb3Weg4mwW8p9BdMMH+L1k2Fb7F5yLHCjveV+Tswj+VILCMjpjXb4DUYBF1ifsYDFavM+XRuv90Q2ZyoT7YhJ+Z+J8xfCqJtmteywD8BBgDZ8yd0Dz2RkwAAAABJRU5ErkJggg=='
                                width={33}
                                height={39}
                                alt="ledger"
                            />
                        </figure>
                    </li>
                    <li className={classes.li}>
                        <figure>
                            <img src={"https://infinitywallet.io/images/wallet_pump.png"} 
                            alt={"safepal"} 
                            width={40} 
                            height={46} />
                        </figure>
                    </li>
                </ul>
            </div >
        </div >
    )
}