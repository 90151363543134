
const Educational = () => {
  return (
    <footer className="p-3 pt-0 min-h-screen flex justify-center items-center">
      {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 px-[15px] gap-4"> */}
      <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 px-[15px] gap-4">
        <div className="my-[1px] px-[1px]">
          <div>Educational:</div>
          <p className="mt-[1px] text-gray-400">
          -Now that we live in the 21st century, there are many job opportunities.
Humanity has made significant progress.
Financial markets are a good example of this. But it plays a very, very important role in the continuation of these developments.
 - Financial markets are directly related to money, and this causes more accuracy and obsession in choices.
 - Continuous success requires a reliable and high-quality education system so that a person can learn all the details in this field and take advantage of the opportunities.
 - KHF is a perfect example of the needs of every person, company, etc., who is active or intends to be active in this field.
 - The KHF education department and system is designed as a win-win. A person from zero to 50 will learn all the necessary training, and by reaching 100, in addition to not needing other training, he will also earn.  has achieved material success.
          </p>
        </div>
        
      </div>
    </footer>
  );
};

export default Educational;
