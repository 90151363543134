import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAccount,
  setChainId,
  setInvestingContract,
  setInvestingTokenContract,
  setLibrary,
  setShouldDisconnect,
  setShouldLoad,
  setStakingContract,
  setStakingV2Contract,
  setTokenContract,
} from "../../features/dapp/dappSlice";
// import { ethers, Signer } from "ethers";

import { investingAbi, InvestingAddress, InvestingUsdtAddress, stakingAbi, StakingAddress, stakingV2Abi, StakingV2Address, usdtAbi, UsdtAddress } from "../../utils/Abi";
import Slider from "../Slider";
import WalletModal from "../WalletModal";
import { ethers } from "ethers";
import { web3Modal } from "../Web3Modal";
import { ConnectWallet, useSigner } from "@thirdweb-dev/react";



export default function InvestingConnect() {
  // const [provider, setProvider] = useState<any>(null);
  const [isEnabled, setIsEnabled] = useState(false); //* Flag for open and close connect wallet modal *//
  // const { chainId, address, connector, chainSupported } = useAccount();
  const [provider, setProvider] = useState<any>();

  const signer = useSigner()

  const dispatch = useDispatch();
  const library = useSelector((state: any) => state.counter.library);
  const account = useSelector((state: any) => state.counter.account);
  const version = useSelector((state: any) => state.counter.version);

  // const connect = async (connector: any) => {
  //   try {
  //     const provider = await web3Modal.connectTo(connector);
  //     setProvider(provider);
  //     const library = new ethers.providers.Web3Provider(provider);
  //     dispatch(setLibrary(library));
  //     // const signer = await library.getSigner();
  //     // const account = await signer.getAddress();
  //     // dispatch(setAccount(account));
  //     //set conracts
  //     const usdtContract = new ethers.Contract(usdtAddress, usdtAbi, library);
  //     const stakingContract = new ethers.Contract(
  //       stakingAddress,
  //       stakingAbi,
  //       library
  //     );
  //     const stakingV2Contract = new ethers.Contract(
  //       stakingV2Address,
  //       stakingV2Abi,
  //       library
  //     );
  //     const investingTokenContract = new ethers.Contract(
  //       investingUsdtAddress,
  //       usdtAbi,
  //       library
  //     );
  //     const investingContract = new ethers.Contract(
  //       investingAddress,
  //       investingAbi,
  //       library
  //     );
  //     if (stakingContract) {
  //       dispatch(setStakingContract(stakingContract));
  //     }
  //     if (stakingV2Contract) {
  //       dispatch(setStakingV2Contract(stakingV2Contract));
  //     }
  //     if (usdtContract) {
  //       dispatch(setTokenContract(usdtContract));
  //     }
  //     if (investingTokenContract) {
  //       dispatch(setInvestingTokenContract(investingTokenContract));
  //     }
  //     if (investingContract) {
  //       dispatch(setInvestingContract(investingContract));
  //     }
  //     localStorage.setItem("khfedu_Wallet", connector);
  //     setIsEnabled(!isEnabled);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  // useEffect(() => {
  //   provider?.on("disconnect", (error: { code: number; message: string }) => {
  //     console.log(error);
  //   });
  // });

  // async function disconnect() {
  //   // if(library){
  //   try {
  //     localStorage.removeItem("khfedu_Wallet");
  //     await web3Modal.clearCachedProvider();
  //     setProvider(null);
  //     setAccount(null);
  //     dispatch(setAccount(''));
  //     dispatch(setLibrary(null));
  //     dispatch(setChainId(null));
  //     dispatch(setTokenContract(null));
  //     dispatch(setStakingContract(null));
  //     dispatch(setStakingV2Contract(null));
  //     dispatch(setInvestingTokenContract(null));
  //     dispatch(setInvestingContract(null));
  //     dispatch(setShouldLoad(false));
  //     dispatch(setShouldDisconnect(false));
  //   //   console.log('hhh')
  //     library.provider?.disconnect();
  //   } catch (error) {}
  // }

  return (
    <>
      <div
        className={`w-[90%] sm:w-10/12 px-3 py-20 my-24 mx-auto border border-yellow-400 rounded-md`}
        id="Connect"
      >
        <div className="grid grid-cols-1 gap-2">
          <div className="flex flex-col items-center mb-8 lg:mb-0 px-7">
            <h1 className="text-[25px] font-[600]">Connect your wallet </h1>
            <p className="mt-5">
              Please first connect your wallet to see your account details.
            </p>
          </div>
          <div className="flex flex-col items-center px-7 mt-10">
            <div className="flex justify-center items-center self-stretch bg-gray-600 p-4 rounded-sm">
              {/* {address ? <p className="max-w-full break-words">{address}</p>: "Wallet Address"} */}
              {account ? (
                <p className="max-w-full break-words">{account}</p>
              ) : (
                "Wallet Address"
              )}
            </div>
            {/* {account ? (
              <button
                onClick={disconnect}
                className="flex justify-center items-center p-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-red-100 to-red-600 hover:bg-gradient-to-bl rounded-lg transition-all"
              >
                Disconnect
              </button>
            ) : (
              <button
                onClick={() => setIsEnabled(true)}
                className="flex justify-center items-center p-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-500 to-yellow-200 hover:bg-gradient-to-bl rounded-lg transition-all"
              >
                Connect
              </button>
            )} */}
            <div
              className="flex justify-center items-center p-3 mt-4 md:px-[1.8rem] text-sm"
            >
            <ConnectWallet
              dropdownPosition={{
                side: "bottom",
                align: "center",
              }}
            />
            </div>
          </div>
        </div>
        <Slider />
      </div>
      {/* {isEnabled ? (
        <WalletModal
          isEnabled={isEnabled}
          setIsEnabled={setIsEnabled}
          connect={connect}
        />
      ) : null} */}
    </>
  );
}
