import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { setShouldLoad } from "../../features/dapp/dappSlice";
import {
  checkAppIsActive,
  checkConnect,
  checkIsShouldStake,
  checkIsStakeAble,
  getRefer,
  getStakeAmount,
  getStakeAmountV2,
  hasEnoughBalance,
} from "../../hooks/Errors";

import logo from "../../images/logo.png";
import logoG from "../../images/logo-gray.png";

import { StakingDetails } from "../../types/Stake";
import { toEther, toEtherNum, toWei } from "../../utils/utils";
import AlertModal from ".././AlertModal";
import Loading from "../Loading";
import { IoLogoUsd } from "react-icons/io";
import { useSigner } from "@thirdweb-dev/react";

export default function InvestingStake() {
  const [loading, setLoading] = useState(false);
  const [alertModall, setAlertModal] = useState(false);
  const [alertStatus, setAlertStatus] = useState("");
  const [shouldStaked, setShouldStaked] = useState(true);

  const [rank, setRank] = useState(0);
  const [stakeAmount, setStakeAmount] = useState(30);
  const [stakeFee, setStakeFee] = useState(5);
  const [totalStakeAmount, setTotalStakeAmount] = useState(105);
  const [stakeCurrency, setStakeCurrency] = useState("USDT");
  const [payAmount, setPayAmount] = useState<any>('');
  const [isApproved, setIsApproved] = useState(false);
  const [stakeVal, setStakeVal] = useState<number>(100)

  //new investing states
  const [currentRefer, setCurrentRefer] = useState('');
  const [totalInvestmentAmount, setTotalInvestmentAmount] = useState(0);
  const [totalInvestReward, setTotalInvestReward] = useState(0);
  const [remainedInvestReward, setRemainedInvestReward] = useState(0);
  const [investmentStartTime, setInvestmentStartTime] = useState(0);
  const [investmentEndTime, setInvestmentEndTime] = useState(0);
  const [lastClaimedInvestRewardTime, setLastClaimedInvestRewardTime] = useState(0);
  const [isActive, setIsActive] = useState(false);

  const [minimumInvestAmount, setMinimumInvestAmount] = useState(0);
  const [endTimePeriod, setEndTimePeriod] = useState(0);
  const [inviteRewards, setInviteRewards] = useState(0);
  const [allInviteRewards, setAllInviteRewards] = useState(0);
  const [userClaimedRewards, setUserClaimedRewards] = useState(0);
  const [previousUnclaimedInvestReward, setPreviousUnclaimedInvestReward] = useState(0);
  const [freezed, setFreezed] = useState(false);
  const [totalInvestRewardsUntillNow, setTotalInvestRewardsUntillNow] = useState(0);
  const [isUserActive, setIsUserActive] = useState(false);
  const [appIsActive, setAppIsActive] = useState(true);
  const [userTokenBalance, setUserTokenBalance] = useState(0);
  const [owner, setOwner] = useState('');
  

  const dispatch = useDispatch();

  const account = useSelector((state: any) => state.counter.account);
  const library = useSelector((state: any) => state.counter.library);

  const signer = useSigner()
  
  const stakingV2Contract = useSelector(
    (state: any) => state.counter.stakingV2Contract
  );
  const investingTokenContract = useSelector(
    (state: any) => state.counter.investingTokenContract
  );
  const investingContract = useSelector(
    (state: any) => state.counter.investingContract
  );

  const loadData = async () => {
    
    const details = await getStakeAmountV2(stakingV2Contract, account);
    const shouldStake = await checkIsShouldStake(stakingV2Contract, account);
    setShouldStaked(shouldStake);
    setRank(details?.rank);
    setStakeAmount(details?.stakeAmount as number);
    setStakeFee(details?.fee as number);
    setTotalStakeAmount(details?.totalAmount as number);
    setStakeCurrency(details?.stakeCurrency as string);
    setPayAmount(details?.payAmount)
  };

  const checkIsUserActive = (totalInvestmentReward:number, investmentEndTime:number, allInviteRewards:number, totalInvestRewardsUntillNow:number) => {
      const timestamp = Math.floor(Date.now() / 1000);
      console.log("ZZZ", allInviteRewards, totalInvestRewardsUntillNow, totalInvestmentReward);
    if(totalInvestmentReward > 0 && investmentEndTime > 0){
    if(allInviteRewards + totalInvestRewardsUntillNow >= totalInvestmentReward){
        return false;
    } else if(timestamp > investmentEndTime){
        return false;
    } else {
        return true;
    }
    }else {
        return false;
    }
  }



  const getUserInvestDetails = async () => {

    const investInfo = await investingContract.investInfo(account);
    setCurrentRefer(investInfo[0]);
    setTotalInvestmentAmount(toEtherNum(investInfo[1]));
    setTotalInvestReward(toEtherNum(investInfo[2]));
    setRemainedInvestReward(toEtherNum(investInfo[3]));
    setInvestmentStartTime(Number(investInfo[4]));
    setInvestmentEndTime(Number(investInfo[5]));
    setLastClaimedInvestRewardTime(Number(investInfo[6]));
    setIsActive(investInfo[7]);

    /*
    console.log("investInfo", investInfo);
    console.log("refer", investInfo[0]);
    console.log("totalInvestmentAmount", toEtherNum(investInfo[1]));
    console.log("totalInvestReward", toEtherNum(investInfo[2]));
    console.log("remainedInvestReward", toEtherNum(investInfo[3]));
    console.log("investmentStartTime", Number(investInfo[4]));
    console.log("investmentEndTime", Number(investInfo[5]));
    console.log("lastClaimedInvestRewardTime", Number(investInfo[6]));
    console.log("isActive", investInfo[7]);
    */
    const minimumInvestAmount = await investingContract.minimumInvestAmount();
    setMinimumInvestAmount(toEtherNum(minimumInvestAmount));
    // console.log("minimumInvestAmount", toEtherNum(minimumInvestAmount));
    // const endTimePeriod = await investingContract.endTimePeriod();
    // setEndTimePeriod(Number(endTimePeriod));
    // console.log("endTimePeriod", Number(endTimePeriod));
    // const inviteRewards = await investingContract.inviteRewards(account);
    // setInviteRewards(toEtherNum(inviteRewards));
    // console.log("inviteRewards", toEtherNum(inviteRewards));
    const allInviteRewards = await investingContract.allInviteRewards(account);
    setAllInviteRewards(toEtherNum(allInviteRewards));
    // console.log("allInviteRewards", toEtherNum(allInviteRewards));
    // const userClaimedRewards = await investingContract.userClaimedRewards(account);
    // setUserClaimedRewards(toEtherNum(userClaimedRewards));
    // console.log("userClaimedRewards", toEtherNum(userClaimedRewards));
    // const previousUnclaimedInvestReward = await investingContract.previousUnclaimedInvestReward(account);
    // setPreviousUnclaimedInvestReward(toEtherNum(previousUnclaimedInvestReward));
    // console.log("previousUnclaimedInvestReward", toEtherNum(previousUnclaimedInvestReward));
    // const freezed = await investingContract.freezed(account);
    // setFreezed(freezed);
    // console.log("freezed", freezed);
    const totalInvestRewardsUntillNow = await investingContract.getTotalInvestRewardsUntillNow(account);
    setTotalInvestRewardsUntillNow(toEtherNum(totalInvestRewardsUntillNow));
    // console.log("totalInvestRewardsUntillNow", toEtherNum(totalInvestRewardsUntillNow));
    const isUserActive = checkIsUserActive(toEtherNum(investInfo[2]), Number(investInfo[5]), toEtherNum(allInviteRewards), toEtherNum(totalInvestRewardsUntillNow));
    setIsUserActive(isUserActive);
    // console.log("isUserActive", (isUserActive));
    const appIsUnActive = await investingContract.paused();
    setAppIsActive(!appIsUnActive);
    // console.log("appIsActive", (!appIsUnActive));
    const userTokenBalance = await investingTokenContract.balanceOf(account);
    setUserTokenBalance(toEtherNum(userTokenBalance));
    // console.log("user token balance", toEther(userTokenBalance));
    const owner = await investingContract.owner();
    setOwner(owner);
    // console.log("Owner", owner);
    
  }


  const getUserRefer = async () => {
    // check if user has refer
    if(currentRefer && currentRefer != "0x0000000000000000000000000000000000000000"){
      const isStaker = await investingContract.isRegistered(currentRefer);
      if(isStaker){
        return {
          address: currentRefer,
          isCorrect: true
        }
      }else{
        return {
          address: owner,
          isCorrect: true
        }
      }
    }
    //check if user doesn't have refer
    const url = window.location.href;
    let index = url.indexOf('?ref=');
    let referAdd = '';
    if (index >= 0) {
        referAdd = url.substring(index + 5, index + 5 + 42);
        console.log("referAdd:", referAdd)
        const referIsStaker = await investingContract.isRegistered(referAdd);

        if(referAdd === account){
          toast.error(`Your cant put your address as refer!`)
          return {
            address: owner,
            isCorrect: false
          }
        }
        if(referIsStaker === false){
          toast.error(`Your refer address is not investor!`)
          return {
            address: owner,
            isCorrect: false
          }
        }
        
        if(referIsStaker === true){
          return {
            address: referAdd,
            isCorrect: true
          }
        }
    }
    else {
      return {
        address: owner,
        isCorrect: true
      }
    }
  }

  const approve = async () => {
    checkConnect(account);
    try {
    if(investingTokenContract){
        setLoading(true);
        if(appIsActive==false) { 
            toast.error("The app is stopped, please wait for the later start time.")
            return setLoading(false);
        }
        if (userTokenBalance < totalStakeAmount) {
            toast.error(`Your balance is not enough to stake! (require: ${totalStakeAmount} $)`)
            return setLoading(false);
        } 
        const refer = await getUserRefer();
        console.log("Refer:", refer);
        if (refer?.isCorrect === false) return setLoading(false);
        
        // const signer = await library.getSigner();
        if( stakeVal < minimumInvestAmount){
          toast.error(`Minimum pay Amount is ${minimumInvestAmount} $`);
          setLoading(false);
          return;
        }
        const amountToPay = stakeVal*1.05;
        await investingTokenContract.connect(signer).approve(investingContract.address, toWei(amountToPay.toString()));
        setLoading(false);
        setIsApproved(true);
        }        
        } catch (error) {
        console.log(error);
        setLoading(false);
        }
  }



  const stake = async () => {
    checkConnect(account);
    try {
      setLoading(true);
      if (investingContract) {

        if(appIsActive==false) { 
            toast.error("The app is stopped, please wait for the later start time.")
            return setLoading(false);
        }

        if (userTokenBalance < totalStakeAmount) {
            toast.error(`Your balance is not enough to stake! (require: ${totalStakeAmount} $)`)
            return setLoading(false);
        }

        const refer = await getUserRefer();
        if (refer?.isCorrect === false) return setLoading(false);

    
        let res;
        if(isApproved ===false){toast.error("Please First approve");setLoading(false);return}
        
        // const signer = await library.getSigner();
        if(stakeVal < 100){
            toast.error(`Minimum pay Amount is ${minimumInvestAmount} $`);
            setLoading(false);
            return;
        }
          console.log("refer", owner);
          const amountToPay = stakeVal*1.05;
          res = await investingContract.connect(signer).invest(toWei(amountToPay.toString()), toWei(stakeVal.toString()), refer?.address, {
            gasLimit: 600000,
          });
        
        
        const receipt = await res.wait();
        if (receipt.status == 1) {
          await getUserInvestDetails();
          dispatch(setShouldLoad(true as any));
          setLoading(false);
          setAlertStatus("success");
          setIsApproved(false);
          setAlertModal(true);
        } else {
          setLoading(false);
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    if(investingContract && account){
        getUserInvestDetails();
    }
    // if (stakingV2Contract && account) {
    // loadData();
    // }
    if(!investingContract || !account){
      setShouldStaked(true);
      setRank(0);
      setStakeAmount(100);
      setStakeFee(5);
      setTotalStakeAmount(105);
      setPayAmount(105);
      setStakeCurrency("USDT");
    }
  }, [account, investingContract]);


  const calculatePayAmount = async () => {
      setStakeAmount(stakeVal);
      setTotalStakeAmount(stakeVal*(100+5)/100);
      setPayAmount(stakeVal*(100+5)/100);
  }

  useEffect(() => {
    calculatePayAmount()
  },[stakeVal])

  return (
    <div
      className="w-[90%] sm:w-10/12 px-3 py-20 my-24 mx-auto border border-yellow-400 rounded-md"
      id="Stake"
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        <div className="flex items-center justify-center lg:justify-start px-7">
          <img src={isUserActive ? logo :logoG} alt="Coin Image" />
        </div>
        <div className="flex flex-col justify-center px-7 order-first lg:order-last mb-8 lg:mb-0">
          <h1 className="text-[25px] font-[600]">Invest</h1>
          <p className="mt-5">
            By current input value you should register {stakeAmount}$ + {stakeAmount*0.05}%
            fee.
          </p>
          <p className="mt-5"><b>Active Currency:</b> {stakeCurrency} </p>
          <p className="mt-5"><b>You should pay:</b> {payAmount} {stakeCurrency} </p>
          {rank === 0 ? (
          <div className="flex justify-center items-center mt-8">
          <div className="relative">
          <input
              className="w-full border bg-gray-700 border-yellow-400 rounded-2xl p-[12px] pl-[20px] pr-[40px] placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
              type="number"
              id="stake"
              placeholder="between 30 to 300"
              value={stakeVal}
              onChange={(e: any) => setStakeVal(e.target.value)}
            />
            <IoLogoUsd className="absolute top-[50%] translate-y-[-50%] right-3" />
            </div>
            
            </div>
            ) : null}
          { !isApproved ? <button
            type="button"
            onClick={() => approve()}
            // disabled={true}
            className="flex justify-center items-center p-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-600 to-yellow-100 hover:bg-gradient-to-bl rounded-lg transition-all"
          >
            Approve
          </button> : null}
          <button
            type="button"
            onClick={() => stake()}
            className="flex justify-center items-center p-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-yellow-500 to-yellow-200 hover:bg-gradient-to-bl rounded-lg transition-all"
          >
            Active
          </button>
        </div>
      </div>
      {loading ? <Loading loading={loading} setLoading={setLoading} /> : null}
      {alertModall ? (
        <AlertModal
          alertModal={alertModall}
          status={alertStatus}
          setAlertModal={setAlertModal}
          setStatus={setAlertStatus}
        />
      ) : null}
    </div>
  );
}
