import { useSelector } from "react-redux";
import Connect from "../Connect";
import CopyRight from "../CopyRight";
import InvestingStake from "./InvestingStake";
import InvestingHome from "./InvestingHome";
import InvestingDetails from "./InvestingDetails";
import InvestingInvite from "./InvestingInvite";
import InvestingConnect from "./InvestingConnect";

const InvestingHomePage = () => {

  const version = useSelector((state: any) => state.counter.version);

  return (
  <>
  <InvestingHome/>
  <InvestingConnect/>
  <InvestingStake/>
  <InvestingDetails/>
  <InvestingInvite/>
  <CopyRight />
  </>
  )
  
};

export default InvestingHomePage;
