import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  account: '',
  library: null,
  chainId: null,
  tokenContract: null,
  stakingContract: null,
  stakingV2Contract: null,
  investingTokenContract: null,
  investingContract: null,
  shouldLoad:false,
  shouldDisConnect:false,
  version: 2
}

export const formSlice = createSlice({
  name: "counter",
  initialState,
  reducers: {
    setAccount: (state:any, action:any) => {
      state.account = action.payload;
    },
    setLibrary: (state:any, action:any) => {
      state.library = action.payload
    },
    setChainId: (state:any, action:any) => {
      state.chainId = action.payload
    },
    setTokenContract: (state:any, action:any) => {
      state.tokenContract = action.payload
    },
    setStakingContract: (state:any, action:any) => {
      state.stakingContract = action.payload
    },
    setStakingV2Contract: (state:any, action:any) => {
      state.stakingV2Contract = action.payload
    },
    setInvestingTokenContract: (state:any, action:any) => {
      state.investingTokenContract = action.payload
    },
    setInvestingContract: (state:any, action:any) => {
      state.investingContract = action.payload
    },
    setShouldLoad: (state:any, action:any) => {
      state.shouldLoad = action.payload
    },
    setShouldDisconnect: (state:any, action:any) => {
      state.shouldDisConnect = action.payload
    },
    setVersion: (state:any, action:any) => {
      state.version = action.payload;
    },
  },
});

export const {setAccount, setLibrary, setChainId, setTokenContract, setStakingContract, setStakingV2Contract, setInvestingTokenContract, setInvestingContract, setShouldLoad, setShouldDisconnect, setVersion} = formSlice.actions;

export default formSlice.reducer;
