import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./features/store";

import ScrollToTop from "./components/ScrollToTop";

import { Web3ReactProvider } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";

import { ThirdwebProvider, coinbaseWallet, embeddedWallet, metamaskWallet, trustWallet, walletConnect } from '@thirdweb-dev/react';
import { Binance, Goerli, Sepolia } from '@thirdweb-dev/chains';
// import { TBinance } from "./chains/TBinance";

function getLibrary(provider: any) {
  if (provider) {
      return new Web3Provider(provider);
  }
}



const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    {/* <Web3ReactProvider getLibrary={getLibrary}> */}
      <Provider store={store}>
        <BrowserRouter>
          <ScrollToTop />
          <ThirdwebProvider 
            
            supportedWallets={[
              embeddedWallet(),
              metamaskWallet(),
              coinbaseWallet(),
              walletConnect({
                projectId: "820bdac956100e3eaa071bd10b8c2c39",
              }),
              trustWallet()
            ]}
            clientId="317fc407a75bdead8223bc3136d465e6"
            supportedChains={[Binance, Sepolia]}
            autoSwitch
            >
              <App />
          </ThirdwebProvider>
        </BrowserRouter>
      </Provider>
      {/* </Web3ReactProvider> */}
  </React.StrictMode>
);
