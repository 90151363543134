import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setAccount,
  setChainId,
  setLibrary,
  setShouldDisconnect,
  setShouldLoad,
  setStakingContract,
  setStakingV2Contract,
  setTokenContract,
} from "../features/dapp/dappSlice";
// import { ethers, Signer } from "ethers";

import { InvestingAddress, stakingAbi, StakingAddress, stakingV2Abi, StakingV2Address, usdtAbi, UsdtAddress } from "../utils/Abi";
import Slider from "./Slider";
import WalletModal from "./WalletModal";
import { ethers } from "ethers";
import { web3Modal } from "./Web3Modal";
import { ConnectWallet, useAddress, useChainId, useSigner } from "@thirdweb-dev/react";
import { Provider } from "@ethersproject/providers";



export default function Connect() {
  // const [provider, setProvider] = useState<any>(null);
  const [isEnabled, setIsEnabled] = useState(false); //* Flag for open and close connect wallet modal *//
  // const { chainId, address, connector, chainSupported } = useAccount();
  const [provider, setProvider] = useState<any>();
  const signer = useSigner();
  const currentChainId = useChainId()
  // const pp = use

  // useEffect(() => {
  //   const checkSigner = async () => {
  //   if(signer){
  //     // const library = new ethers.providers.Web3Provider(signer);
  //     // const lll = signer.provider.
  //     const library = signer.provider;
  //     const usdtContract = new ethers.Contract(usdtAddress, usdtAbi, library);
  //     await usdtContract.connect(signer).approve(investingAddress, '1');
  //     // console.log("is signers the same:", signer == newSigner);
  //   }
  //   }
  //   checkSigner()
  // },[signer])

  const accountAddress = useAddress()
  const dispatch = useDispatch();
  const library = useSelector((state: any) => state.counter.library);
  const account = useSelector((state: any) => state.counter.account);
  const version = useSelector((state: any) => state.counter.version);
  /**
  const updateContracts = async (connector: any) => {
    try {
      if(signer){
      // const provider = await web3Modal.connectTo(connector);
      // setProvider(provider);
      // const library = new ethers.providers.Web3Provider(provider);
      const library = signer.provider;
      dispatch(setLibrary(library as any));
      // const signer = await library.getSigner();
      // const account = await signer.getAddress();
      if(accountAddress){
      dispatch(setAccount(accountAddress));
      }
      //set conracts
      const usdtContract = new ethers.Contract(UsdtAddress(currentChainId), usdtAbi, library);
      const stakingContract = new ethers.Contract(
        StakingAddress(currentChainId),
        stakingAbi,
        library
      );
      const stakingV2Contract = new ethers.Contract(
        StakingV2Address(currentChainId),
        stakingV2Abi,
        library
      );
      if (stakingContract) {
        dispatch(setStakingContract(stakingContract));
      }
      if (stakingV2Contract) {
        dispatch(setStakingV2Contract(stakingV2Contract));
      }
      if (usdtContract) {
        dispatch(setTokenContract(usdtContract));
      }
      // localStorage.setItem("khfedu_Wallet", connector);
      setIsEnabled(!isEnabled);
      }else{
        disconnect()
      }
    } catch (error) {
      console.log(error);
    }
  };
 */
  // useEffect(() => {
  //   signer?.provider?.on("disconnect", (error: { code: number; message: string }) => {
  //     console.log(error);
  //   });
  // });
  /**
  async function disconnect() {
    if(!signer){
    try {
      // localStorage.removeItem("khfedu_Wallet");
      // await web3Modal.clearCachedProvider();
      setProvider(null);
      setAccount(null);
      dispatch(setAccount(''));
      dispatch(setLibrary(null));
      dispatch(setChainId(null));
      dispatch(setTokenContract(null));
      dispatch(setStakingContract(null));
      dispatch(setStakingV2Contract(null));
      dispatch(setShouldLoad(false));
      dispatch(setShouldDisconnect(false));
      console.log('hhh')
      // library.provider?.disconnect();
    } catch (error) {
      console.log("disconnect error:", error)
    }
    }
  }
   */
  return (
    <>
      <div
        className={`w-[90%] sm:w-10/12 px-3 py-20 my-24 mx-auto border border-${version==1 ? "solid" : "blue-400"} rounded-md`}
        id="Connect"
      >
        <div className="grid grid-cols-1 gap-2">
          <div className="flex flex-col items-center mb-8 lg:mb-0 px-7">
            <h1 className="text-[25px] font-[600]">Connect your wallet </h1>
            <p className="mt-5">
              Please first connect your wallet to see your account details.
            </p>
          </div>
          <div className="flex flex-col items-center px-7 mt-10">
            <div className="flex justify-center items-center self-stretch bg-gray-600 p-4 rounded-sm">
              {/* {address ? <p className="max-w-full break-words">{address}</p>: "Wallet Address"} */}
              {accountAddress ? (
                <p className="max-w-full break-words">{accountAddress}</p>
              ) : (
                "Wallet Address"
              )}
            </div>
            {/* {account ? (
              <button
                onClick={disconnect}
                className="flex justify-center items-center p-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-red-100 to-red-600 hover:bg-gradient-to-bl rounded-lg transition-all"
              >
                Disconnect
              </button>
            ) : (
              <button
                onClick={() => setIsEnabled(true)}
                className="flex justify-center items-center p-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-lg transition-all"
              >
                Connect
              </button>
            )} */}
            <div
              className="flex justify-center items-center p-3 mt-4 md:px-[1.8rem] text-sm"
            >
            <ConnectWallet
              dropdownPosition={{
                side: "bottom",
                align: "center",
              }}
            />
            </div>
          </div>
        </div>
        <Slider />
      </div>
      {/* {isEnabled ? (
        <WalletModal
          isEnabled={isEnabled}
          setIsEnabled={setIsEnabled}
          connect={connect}
        />
      ) : null} */}
    </>
  );
}
