import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";

export default function Invite() {

  const account = useSelector((state: any) => state.counter.account);

  const [isCopied, setIsCopied] = useState(false);
  const [inviteLink, setInviteLink] = useState('');
  const version = useSelector((state: any) => state.counter.version);
  
  const getInviteLink = async () => {
    let copyText = window.location.host;
    if(account){
      copyText = copyText + '/?ref=' + account;
      setInviteLink(copyText);
    }else{
      setInviteLink("Please connect your wallet.");
    }
  }

  useEffect(() => {
    getInviteLink();
  },[account]);


  const copyTextToClipboard = async (text: string) => {
    if ("clipboard" in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand("copy", true, text);
    }
  };

  const handleCopyClick = () => {
    copyTextToClipboard(inviteLink)
      .then(() => {
        setIsCopied(true);
        setTimeout(() => {
          setIsCopied(false);
        }, 2000);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  
  return (
    <div
      className={`w-[90%] sm:w-10/12 px-3 py-20 my-24 mx-auto border border-${version==1 ? "solid" : "blue-400"} rounded-md`}
      id="Invite"
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-2">
        <div className="flex flex-col px-7">
          {/* <div> */}
          <p className="mb-2">Generate invite link</p>
          <input
            className="w-full border bg-gray-700 border-gray-600 rounded-2xl p-[12px] pl-[20px] pr-[40px] placeholder:text-[16px] placeholder-gray-400 text-white focus:ring-blue-500 focus:border-blue-500"
            type="text"
            value={inviteLink}
            readOnly
          />
          <div className="flex justify-center items-center">
            <button
              onClick={handleCopyClick}
              className="flex justify-center items-center p-3 mt-4 font-[400] md:px-[1.8rem] text-sm text-white md:text-[1.2rem] md:leading-6 bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl rounded-lg transition-all"
            >
              <span>{isCopied ? "Copied!" : "Copy"}</span>
            </button>
          </div>
          {/* </div> */}
        </div>

        <div className="flex flex-col px-7 order-first lg:order-last mb-8 lg:mb-0">
          <h1 className="text-[25px] font-[600]">Invite recieve reward</h1>
          <p className="mt-5">
            You can invite your friends with your invitation link. For everyone
            who register and participates in our Dapp , you will recieve 40%
            of Active amount for reward.
          </p>
        </div>
      </div>
    </div>
  );
}
